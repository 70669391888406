/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPasswordChangeSuccess } from "../features/authentication/authSlice";
import desktopVideo from "../images/home_page_assets/desktop.mp4";
import mobileVideo from "../images/home_page_assets/mobilesize.mp4";
import theme from "../utils/themes";
import PromoImgOne from "../images/home_page_assets/img-one.webp";
import PromoImgTwo from "../images/home_page_assets/img-two.webp";
import PromoImgThree from "../images/home_page_assets/img-three.webp";
import PromoImgFour from "../images/home_page_assets/img-four.webp";
import PromoImgFive from "../images/home_page_assets/img-five.webp";
import PromoImgOneMobile from "../images/home_page_assets/img-one-mobile.webp";
import PromoImgTwoMobile from "../images/home_page_assets/img-two-mobile.webp";
import PromoImgThreeMobile from "../images/home_page_assets/img-three-mobile.webp";
import PromoImgFourMobile from "../images/home_page_assets/img-four-mobile.webp";
import PromoImgFiveMobile from "../images/home_page_assets/img-five-mobile.webp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";

const Container = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  flexDirection: "column",
}));

const TopTextContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  color: "white",
  textAlign: "center",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.between("sm", "md")]: {
    bottom: "70px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "75%",
    margin: "auto",
    bottom: "70px",
  },
}));

const InnerTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "42px",
  lineHeight: "50px",
  color: "#fff",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
    lineHeight: "28px",
    marginBottom: theme.spacing(0.5),
  },
}));

const InnerText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  color: "#fff",
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    lineHeight: "20px",
    marginBottom: theme.spacing(0.5),
  },
}));

const InnerLink = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  transition: "color 0.3s",
  cursor: "pointer",
  marginBottom: theme.spacing(1),
  position: "relative",
  width: "fit-content",
  textAlign: "center",
  color: "#fff",
  "&:hover": {
    "&::after": {
      width: "30px",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "0",
    height: "2px",
    backgroundColor: "#fff",
    transition: "width 0.3s ease-out",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "block",
}));

const ImageTextContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "2%",
  left: "2%",
  right: "2%",
  alignItems: "flex-end",
  boxSizing: "border-box",
  color: "white",
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "85%",
    margin: "auto",
    paddingLeft: theme.spacing(0),
  },
}));

const ImageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "42px",
  lineHeight: "50px",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    lineHeight: "28px",
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "22px",
    lineHeight: "28px",
    textAlign: "center",
  },
}));

const ImageText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  color: "#fff",
  lineHeight: "24px",
  marginBottom: "8px",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    lineHeight: "27px",
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const ImageLink = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  transition: "color 0.3s",
  cursor: "pointer",
  marginBottom: theme.spacing(1),
  position: "relative",
  width: "fit-content",
  textAlign: "center",
  color: "#fff",
  "&:hover": {
    "&::after": {
      width: "30px",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "0",
    height: "2px",
    backgroundColor: "#fff",
    transition: "width 0.3s ease-out",
  },
}));

const RightTextContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  height: "100%",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: `${theme.spacing(5)} 0px`,
  },
}));

const RightTitle = styled(Box)(({ theme }) => ({
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "34px",
  color: "#000",
  marginBottom: theme.spacing(2),
}));

const RightText = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#000",
  marginBottom: theme.spacing(2),
}));

const CheckoutButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  padding: "0px",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "#fff",
  },
  "& .MuiButton-icon": {
    margin: "0px",
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "150px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const BottomText = styled(Box)(({ theme }) => ({
  fontSize: "36px",
  fontWeight: 700,
  lineHeight: "43px",
}));

const VideoComponent = styled("video")(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: "100%",
  },
  // [theme.breakpoints.down("md")]: {
  //   width: "100%",
  // },
}));

function HomePage() {
  const [videoSrc, setVideoSrc] = useState(desktopVideo);
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { passwordChangeSuccess } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  function updateVideoSrc() {
    if (matchesMobileScreen) {
      setVideoSrc(mobileVideo);
    } else {
      setVideoSrc(desktopVideo);
    }
  }

  useEffect(() => {
    updateVideoSrc();
  }, [matchesMobileScreen]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={passwordChangeSuccess}
        autoHideDuration={5000}
        sx={{
          "&.MuiSnackbar-root": {
            top: "100px",
          },
        }}
      >
        <Alert
          onClose={() => {
            dispatch(setPasswordChangeSuccess(false));
          }}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Password Set Successfully
        </Alert>
      </Snackbar>
      <Container>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              "&.MuiGrid-item": {
                [theme.breakpoints.up("lg")]: {
                  height: "auto",
                },
                height: "100svh",
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "100svh",
                width: "100%",
                overflow: "hidden",
                objectFit: "cover",
                [theme.breakpoints.down("lg")]: {
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                },
                [theme.breakpoints.down("sm")]: {
                  height: "100%",
                },
              }}
            >
              <VideoComponent autoPlay loop muted className="bg-vid">
                <source src={videoSrc} type="video/mp4" />
              </VideoComponent>
              <TopTextContainer>
                <InnerTitle>Summer Linen</InnerTitle>
                <InnerText>
                  Effortless and lightweight pieces to wear all season.
                </InnerText>
                <InnerLink>Shop now</InnerLink>
              </TopTextContainer>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ImageContainer>
              <Image
                src={matchesMobileScreen ? PromoImgOneMobile : PromoImgOne}
                alt="promo-img-one"
              />
              <ImageTextContainer>
                <ImageTitle>This is Pride</ImageTitle>
                <ImageText>
                  Say it with body language in limited-edition underwear and
                  apparel
                </ImageText>
                <ImageLink>Shop Now</ImageLink>
              </ImageTextContainer>
            </ImageContainer>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ImageContainer>
              <Image
                src={matchesMobileScreen ? PromoImgTwoMobile : PromoImgTwo}
                alt="promo-img-two"
              />
              <ImageTextContainer>
                <ImageTitle>Activewear for the Heat</ImageTitle>
                <ImageText>
                  High breathability. Moisture wicking Quick-dry fabric.
                </ImageText>
                <ImageLink>Shop Now</ImageLink>
              </ImageTextContainer>
            </ImageContainer>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ImageContainer>
              <Image
                src={matchesMobileScreen ? PromoImgThreeMobile : PromoImgThree}
                alt="promo-img-three"
              />
              {matchesMobileScreen && (
                <ImageTextContainer>
                  <ImageTitle>The Kikani Activewear</ImageTitle>
                  <ImageText>
                    High breathability. Moisture wicking Quick-dry fabric.
                  </ImageText>
                  <ImageLink>Shop Now</ImageLink>
                </ImageTextContainer>
              )}
            </ImageContainer>
          </Grid>
          {!matchesMobileScreen && (
            <Grid item xs={12} sm={6} md={6}>
              <RightTextContainer>
                <RightTitle>The KIKANI</RightTitle>
                <RightText>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a .
                </RightText>
                <CheckoutButton
                  disableRipple
                  endIcon={<KeyboardArrowRightSharpIcon />}
                >
                  Checkout
                </CheckoutButton>
              </RightTextContainer>
            </Grid>
          )}
          <Grid item md={12}>
            <ImageContainer>
              <Image
                src={matchesMobileScreen ? PromoImgFourMobile : PromoImgFour}
                alt="promo-img-four"
              />
              <ImageTextContainer>
                <ImageTitle>Bestselling activewear</ImageTitle>
                <ImageText>
                  The favorites for a reason. Find your light in cotton comfort.
                </ImageText>
                <ImageLink>Shop Now</ImageLink>
              </ImageTextContainer>
            </ImageContainer>
          </Grid>
          <Grid item md={12}>
            <ImageContainer>
              <Image
                src={matchesMobileScreen ? PromoImgFiveMobile : PromoImgFive}
                alt="promo-img-five"
              />
            </ImageContainer>
          </Grid>
        </Grid>
        <BottomContainer>
          <BottomText>#Be Limitless</BottomText>
        </BottomContainer>
      </Container>
    </>
  );
}

export default HomePage;
