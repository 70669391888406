import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { useDispatch, useSelector } from "react-redux";
import {
  editUserDetails,
  fetchUserDetails,
} from "../features/authentication/authSlice";
import loader from "../images/loader.gif";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  maxWidth: "1440px",
  width: "90%",
  margin: "auto",
  minHeight: "70vh",
  boxSizing: "border-box",
  padding: `${theme.spacing(2)} 0px`,
  display: "flex",
  alignItems: "center",
}));

const Form = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const ImgInputContainer = styled(Box)(({ theme }) => ({
  height: "180px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
  "& ::after": {
    borderBottom: "0.125rem solid #e4e4e4",
  },
  // Filled input style
  "& .MuiFilledInput-input": {
    backgroundColor: "#fff",
  },
  "& .MuiFilledInput-root": {
    "& ::after": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #e4e4e4",
      "& :hover": {
        borderColor: "0px solid #e4e4e4",
      },
    },
  },
}));

const EditBtn = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "3.75rem",
  maxWidth: "100%",
  textTransform: "uppercase",
  fontSize: "0.875rem",
  borderRadius: "0px",
}));

const SaveBtn = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "3.75rem",
  maxWidth: "100%",
  textTransform: "uppercase",
  fontSize: "0.875rem",
  borderRadius: "0px",
}));

const CancelBtn = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "3.75rem",
  maxWidth: "100%",
  textTransform: "uppercase",
  fontSize: "0.875rem",
  borderRadius: "0px",
}));

const EditDiv = styled(Box)(({ theme, isEdit }) => ({
  display: !isEdit ? "none" : "block",
  position: "absolute",
  bottom: 0,
  zIndex: 1,
  cursor: "pointer",
  paddingTop: "5px",
  paddingBottom: "5px",
  backgroundColor: "#000000b2",
  color: "#ffffff",
  textAlign: "center",
  fontSize: "small",
  width: "100%",
}));

const LoadingLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
}));

function MyProfilePage() {
  const { user, status } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const fileInputRef = useRef(null);
  const [userDetails, setUserDetails] = useState({
    img: "",
    imgFile: {},
    first_name: "",
    last_name: "",
    contact_no: "",
    email_address: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setUserDetails((prevState) => ({
        ...prevState,
        img: URL.createObjectURL(selectedFile),
        imgFile: selectedFile,
      }));
    }
  };

  const saveBtnHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("first_name", userDetails.first_name);
      formData.append("last_name", userDetails.last_name);
      formData.append("email", userDetails.email_address);
      if (userDetails.img) {
        formData.append("profile_pic", userDetails.imgFile); // Append the image file
      }
      const response = await dispatch(editUserDetails(formData)).unwrap();
      console.log(response);
      dispatch(fetchUserDetails());
      setIsEdit(false);
    } catch (error) {
      console.log("Error while saving the data: ", error.message);
    }
  };

  useEffect(() => {
    if (user) {
      setUserDetails({
        img: user.profile_img,
        first_name: user.first_name,
        last_name: user.last_name,
        email_address: user.email,
      });
    }
  }, [user]);

  return (
    <>
      {status === "loading" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <LoadingLogo src={loader} alt="site-loader" />
        </Backdrop>
      )}
      <Container>
        <InnerContainer>
          <Form>
            <ImgInputContainer>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "50%",
                  overflow: "hidden",
                  width: "160px",
                  height: "160px",
                }}
              >
                <EditDiv isEdit={isEdit} onClick={handleButtonClick}>
                  <EditSharpIcon
                    sx={{ fontSize: "13px", marginRight: "5px" }}
                  />
                  Edit
                </EditDiv>
                <IconButton
                  sx={{ padding: "0px" }}
                  disabled={!isEdit}
                  onClick={handleButtonClick}
                >
                  <Avatar
                    src={userDetails.img}
                    sx={{
                      objectFit: "cover",
                      height: "160px",
                      width: "160px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  />
                </IconButton>
              </div>
              <Input
                type="file"
                inputRef={fileInputRef}
                sx={{ display: "none" }}
                onChange={handleFileChange}
                inputProps={{ accept: "image/*" }}
              />
            </ImgInputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  value={userDetails.first_name}
                  name="first_name"
                  onChange={handleChange}
                  variant={isEdit ? "outlined" : "filled"}
                  label="First Name"
                  InputProps={{ readOnly: !isEdit }}
                  focused={userDetails.first_name}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  value={userDetails.last_name}
                  name="last_name"
                  onChange={handleChange}
                  variant={isEdit ? "outlined" : "filled"}
                  label="Last Name"
                  InputProps={{ readOnly: !isEdit }}
                  focused={userDetails.last_name}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Input
                  value={userDetails.email_address}
                  name="email_address"
                  onChange={handleChange}
                  variant={isEdit ? "outlined" : "filled"}
                  label="Email Address"
                  InputProps={{ readOnly: true }}
                  focused={userDetails.email_address}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {isEdit ? (
                  <SaveBtn variant="contained" onClick={saveBtnHandler}>
                    Save
                  </SaveBtn>
                ) : (
                  <EditBtn
                    variant="contained"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  >
                    Edit Profile
                  </EditBtn>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {isEdit && (
                  <CancelBtn
                    variant="outlined"
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  >
                    Cancel
                  </CancelBtn>
                )}
              </Grid>
            </Grid>
          </Form>
        </InnerContainer>
      </Container>
    </>
  );
}

export default MyProfilePage;
