import { styled } from "@mui/material/styles";
import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import React from "react";
import ProductsCard from "./card_widgets/ProductCard";
import { useSelector } from "react-redux";
import theme from "../../utils/themes";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  backgroundColor: "#fff",
  padding: `${theme.spacing(10)} 0px`,
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(2)} 0px`,
  },
}));

function NoProductsFoundComponent() {
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 55px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h3">No Products Found....</Typography>
    </Box>
  );
}

function ProductListingComponentV1({ data, handleChange, filters, pages }) {
  const { status } = useSelector((state) => state.products);
  return (
    <Container>
      <Grid container spacing={1}>
        {status === "loading" ? (
          Array.from(new Array(6)).map((_, index) => (
            <Grid item xs={6} sm={3} md={3} key={index}>
              <ProductsCard loading={true} />
            </Grid>
          ))
        ) : data.length > 0 ? (
          data.map((product, index) => (
            <Grid item xs={6} sm={3} md={3} key={index}>
              <ProductsCard data={product} loading={false} />
            </Grid>
          ))
        ) : (
          <NoProductsFoundComponent />
        )}
      </Grid>
      <Stack
        sx={{ margin: `${theme.spacing(5)} 0px` }}
        justifyContent="center"
        alignItems="center"
      >
        <Pagination
          count={pages}
          currnentPage={filters.currentPage}
          onChange={handleChange}
        />
      </Stack>
    </Container>
  );
}

export default ProductListingComponentV1;
