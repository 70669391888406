import React from 'react'
import { Navigate } from 'react-router';

const RequireOtpToken = ({ children }) => {
    const token = localStorage.getItem("otp_token");
    const set_passsword_token = localStorage.getItem("set_passsword_token");
    const isValideUser = token;
    return isValideUser ? (children) : (<Navigate to={set_passsword_token ? "/add-newpassword" : '/forgot-password'} />)
}

export default RequireOtpToken