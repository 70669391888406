import { Box, Button, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { contactus_page_context } from "../../utils/textUtils";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Form = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "26px",
  lineHeight: "32px",
  color: "#000",
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "0px",
  },
}));

const SubmitBtn = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#000000",
  borderColor: "#000000",
  boxShadow: "none",
  width: "12.5rem",
  height: "3.75rem",
  textTransform: "uppercase",
  fontSize: "0.875rem",
  "&:hover": {
    backgroundColor: "#000000",
    borderColor: "#000000",
  },
  "&.MuiButton-root": {
    borderRadius: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

function ContactFormComponent() {
  const { form_context } = contactus_page_context;
  const [messageData, setMessageData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setMessageData({
      ...messageData,
      [name]: value,
    });
  };

  const submitBtnHandler = (event) => {
    event.preventDefault();
    console.log(messageData);
  };

  return (
    <Container>
      <Title>{form_context.title}</Title>
      <Form component="form" onSubmit={submitBtnHandler}>
        <Input
          name="name"
          value={messageData.name}
          onChange={changeHandler}
          required
          type="text"
          label={form_context.name_field_name}
        />
        <Input
          name="email"
          value={messageData.email}
          onChange={changeHandler}
          required
          type="email"
          label={form_context.email_field_name}
        />
        <Input
          name="message"
          value={messageData.message}
          onChange={changeHandler}
          required
          type="text"
          label={form_context.message_field_name}
          multiline
          rows={10}
        />
        <SubmitBtn type="submit">{form_context.btn_text}</SubmitBtn>
      </Form>
    </Container>
  );
}

export default ContactFormComponent;
