import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { cartbar_context } from "../../utils/textUtils";
import TableCard from "./card_widgets/TableCard";
import theme from "../../utils/themes";
import CartItemCard from "../sidebars/card_widgets/CartItemCard";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const CartTotalContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  border: "1px solid #000",
  maxWidth: "100%",
  padding: theme.spacing(3),
  boxSizing: "border-box",
}));

const CartTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "20px",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

const CheckoutBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  margin: "20px 0px",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const TableCellComponent = styled(TableCell)(({ theme }) => ({
  padding: "14px 0px",
}));

function TableComponent() {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellComponent>PRODUCT</TableCellComponent>
            <TableCellComponent>PRICE</TableCellComponent>
            <TableCellComponent>QUANTITY</TableCellComponent>
            <TableCellComponent>SUBTOTAL</TableCellComponent>
            <TableCellComponent></TableCellComponent>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartbar_context.cartData.map((data, index) => {
            return <TableCard key={index} data={data} index={index} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ShopingBagComponent({ next }) {
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={8}>
          {matches ? (
            <Grid container spacing={2}>
              {cartbar_context.cartData.map((data, index) => (
                <Grid item key={index} xs={12} sm={12} md={12}>
                  <CartItemCard data={data} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <TableComponent />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <CartTotalContainer>
            <CartTitle>CART TOTAL</CartTitle>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCellComponent>
                      <Text>SUBTOTAL</Text>
                    </TableCellComponent>
                    <TableCellComponent>
                      <Text>$ 15</Text>
                    </TableCellComponent>
                  </TableRow>
                  <TableRow>
                    <TableCellComponent>
                      <Text>SHIPPING</Text>
                    </TableCellComponent>
                    <TableCellComponent>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Free shipping"
                        />
                      </FormGroup>
                    </TableCellComponent>
                  </TableRow>
                  <TableRow>
                    <TableCellComponent>
                      <Text>VAT</Text>
                    </TableCellComponent>
                    <TableCellComponent>
                      <Text>$19</Text>
                    </TableCellComponent>
                  </TableRow>
                  <TableRow sx={{ borderBottom: "none" }}>
                    <TableCellComponent sx={{ borderBottom: "none" }}>
                      <Text>TOTAL</Text>
                    </TableCellComponent>
                    <TableCellComponent sx={{ borderBottom: "none" }}>
                      <Text>$ 152</Text>
                    </TableCellComponent>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CartTotalContainer>
          <CheckoutBtn onClick={next}>Proceed To Checkout</CheckoutBtn>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ShopingBagComponent;
