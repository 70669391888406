import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
  border: "1px solid #000",
  maxWidth: "100%",
  padding: theme.spacing(4),
  boxSizing: "border-box",
}));

const OrderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "20px",
  color: "#000",
  textTransform: "uppercase",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: theme.palette.primary.main,
  textTransform: "uppercase",
}));

const ProductText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: "#767676",
  textTransform: "capitalize",
}));

const TextRight = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: theme.palette.primary.main,
  textAlign: "right",
  textTransform: "uppercase",
}));

const TableCellComponent = styled(TableCell)(({ theme }) => ({
  padding: "14px 0px",
}));

const ProductCellComponent = styled(TableCell)(({ theme }) => ({
  padding: "14px 0px",
  border: "none",
}));

function OrderDetailsComponent() {
  return (
    <Container>
      <OrderTitle>Your Order</OrderTitle>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellComponent>
                <Text>Product</Text>
              </TableCellComponent>
              <TableCellComponent>
                <TextRight>Subtotal</TextRight>
              </TableCellComponent>
            </TableRow>
            <TableRow>
              <ProductCellComponent>
                <ProductText>All Day Crossbody Bag x 1</ProductText>
              </ProductCellComponent>
              <ProductCellComponent>
                <TextRight>$76.00</TextRight>
              </ProductCellComponent>
            </TableRow>
            <TableRow>
              <ProductCellComponent>
                <ProductText>All Day Crossbody Bag x 1</ProductText>
              </ProductCellComponent>
              <ProductCellComponent>
                <TextRight>$76.00</TextRight>
              </ProductCellComponent>
            </TableRow>
            <TableRow>
              <ProductCellComponent>
                <ProductText>All Day Crossbody Bag x 1</ProductText>
              </ProductCellComponent>
              <ProductCellComponent>
                <TextRight>$76.00</TextRight>
              </ProductCellComponent>
            </TableRow>
            <TableRow>
              <ProductCellComponent>
                <ProductText>All Day Crossbody Bag x 1</ProductText>
              </ProductCellComponent>
              <ProductCellComponent>
                <TextRight>$76.00</TextRight>
              </ProductCellComponent>
            </TableRow>
            <TableRow>
              <TableCellComponent>
                <Text>SUBTOTAL</Text>
              </TableCellComponent>
              <TableCellComponent>
                <TextRight>$ 15</TextRight>
              </TableCellComponent>
            </TableRow>
            <TableRow>
              <TableCellComponent>
                <Text>SHIPPING</Text>
              </TableCellComponent>
              <TableCellComponent>
                <TextRight>Free shipping</TextRight>
              </TableCellComponent>
            </TableRow>
            <TableRow>
              <TableCellComponent>
                <Text>VAT</Text>
              </TableCellComponent>
              <TableCellComponent>
                <TextRight>$19</TextRight>
              </TableCellComponent>
            </TableRow>
            <TableRow sx={{ borderBottom: "none" }}>
              <TableCellComponent sx={{ borderBottom: "none" }}>
                <Text>TOTAL</Text>
              </TableCellComponent>
              <TableCellComponent sx={{ borderBottom: "none" }}>
                <TextRight>$ 152</TextRight>
              </TableCellComponent>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default OrderDetailsComponent;
