import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const link = process.env.REACT_APP_BACKEND_API;

const refreshToken = async () => {
    try {
        const response = await axios.post(
            `${link}/users/api/token/refresh/`,
            {
                refresh: Cookies.get("refresh"),
            }
        );
        const newAccessToken = response.data.access;
        Cookies.set("access", newAccessToken);
        return newAccessToken;
    } catch (error) {
        throw new Error("Failed to refresh token");
    }
};

export const loginUser = createAsyncThunk(
    "auth/loginUser",
    async ({ username_email, password }, thunkAPI) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${link}/users/api/token/`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    username: username_email,
                    password: password,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error logging in:", error.response.data);
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const signUpUser = createAsyncThunk(
    "auth/signUpUser",
    async ({ first_name, last_name, email, password }, thunkAPI) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${link}/users/api/register/`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    password: password,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error logging in:", error.response.data);
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getNewToken = createAsyncThunk(
    "auth/getNewToken",
    async ({ refreshToken }, thunkAPI) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${link}/users/api/token/refresh/`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    refresh: refreshToken,
                },
            });
            return response.access;
        } catch (error) {
            console.error("Error while getting new token:", error);
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchUserDetails = createAsyncThunk(
    "auth/fetchUserDetails",
    async (_, thunkAPI) => {
        let token = Cookies.get("access");
        const makeRequest = async (token) => {
            return axios({
                method: "POST",
                url: `${link}/users/api/user-profile/`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        };
        try {
            const response = await makeRequest(token);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                try {
                    token = await refreshToken();
                    const response = await makeRequest(token);
                    return response.data;
                } catch (refreshError) {
                    return thunkAPI.rejectWithValue(refreshError.response);
                }
            } else {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        }
    }
);

export const editUserDetails = createAsyncThunk(
    "auth/editUserDetails",
    async (formData, thunkAPI) => {
        try {
            const token = Cookies.get("access");
            const response = await axios({
                method: "POST",
                url: `${link}/users/api/user-profile/edit`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
                data: formData,
            });

            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            // Extracting serializable information from the error
            return thunkAPI.rejectWithValue({
                message: error.message,
                name: error.name,
                code: error.code,
                response: {
                    status: error.response?.status,
                    data: error.response?.data,
                },
            });
        }
    }
);

export const changePassword = createAsyncThunk(
    "auth/changePassword",
    async ({ oldPassword, newPassword }, thunkAPI) => {
        let token = Cookies.get("access");
        const makeRequest = async (token) => {
            return axios({
                method: "POST",
                url: `${link}/users/api/user-password/change`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    old_password: oldPassword,
                    new_password: newPassword,
                },
            });
        };
        try {
            const response = await makeRequest(token);

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                try {
                    token = await refreshToken();
                    const response = await makeRequest(token);

                    return response.message;
                } catch (refreshError) {
                    return thunkAPI.rejectWithValue(refreshError.response);
                }
            } else {
                return thunkAPI.rejectWithValue(error.response);
            }
        }
    }
);

export const verifyEmail = createAsyncThunk(
    "auth/verifyEmail",
    async (email, thunkAPI) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${link}/users/api/get-otp`,
                data: {
                    email: email,
                },
            });
            localStorage.setItem("otp_token", response.data.token);
            localStorage.setItem("otp_time_limit", response.data.valid_till);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
);

export const verifyOtp = createAsyncThunk(
    "auth/verifyOtp",
    async (otp, thunkAPI) => {
        try {
            const verifyToken = localStorage.getItem("otp_token");
            const response = await axios({
                method: "POST",
                url: `${link}/users/api/user-verify/`,
                data: {
                    token: verifyToken,
                    otp: otp,
                },
            });

            if (response.status === 200) {
                localStorage.removeItem("otp_token")
                localStorage.setItem("set_passsword_token", response.data.token);
                return response;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
);

export const addNewPassword = createAsyncThunk(
    "auth/addNewPassword",
    async (newPassword, thunkAPI) => {
        try {
            const token = localStorage.getItem("set_passsword_token");
            const response = await axios({
                method: "POST",
                url: `${link}/users/api/user-password/change-forgot-password`,
                data: {
                    token: token,
                    n_password: newPassword,
                },
            });

            if (response.status === 200) {
                localStorage.clear();
                return response;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
);

const initialState = {
    status: "idle",
    user: {},
    error: null,
    passwordChangeSuccess: false,
    userEmailForVerification: "",
};

export const authSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        resetErrorState: (state) => {
            state.error = null;
        },
        resetUserState: (state) => {
            state.user = null;
        },
        setPasswordError: (state, action) => {
            state.error = action.payload;
        },
        setPasswordChangeSuccess: (state, action) => {
            state.passwordChangeSuccess = action.payload;
        },
        setUserEmailForVerification: (state, action) => {
            state.userEmailForVerification = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.status = "loading";
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
                Cookies.set("access", action.payload.access);
                Cookies.set("refresh", action.payload.refresh);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.payload.detail;
            })
            .addCase(getNewToken.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getNewToken.fulfilled, (state, action) => {
                state.status = "success";
                Cookies.set("access", action.payload);
            })
            .addCase(getNewToken.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.error.message;
            })
            .addCase(signUpUser.pending, (state) => {
                state.status = "loading";
            })
            .addCase(signUpUser.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
                Cookies.set("access", action.payload.access);
                Cookies.set("refresh", action.payload.refresh);
            })
            .addCase(signUpUser.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.payload.message;
            })
            .addCase(fetchUserDetails.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                state.status = "success";
                state.user = action.payload.user;
                state.error = null;
            })
            .addCase(fetchUserDetails.rejected, (state, action) => {
                state.status = "rejected";
            })
            .addCase(editUserDetails.pending, (state) => {
                state.status = "loading";
            })
            .addCase(editUserDetails.fulfilled, (state) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(editUserDetails.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.payload.message;
            })
            .addCase(changePassword.pending, (state) => {
                state.status = "loading";
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.payload.data.old_password[0];
            })
            .addCase(verifyEmail.pending, (state) => {
                state.status = "loading";
            })
            .addCase(verifyEmail.fulfilled, (state) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(verifyEmail.rejected, (state, action) => {
                state.status = "rejected";
                console.log(action.payload.data.message)
                state.error = action.payload.data.message;
                console.log(state.error)
            })
            .addCase(verifyOtp.pending, (state) => {
                state.status = "loading";
            })
            .addCase(verifyOtp.fulfilled, (state) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.payload.data.message;
            })
            .addCase(addNewPassword.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addNewPassword.fulfilled, (state) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(addNewPassword.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.payload.data.message;
            });
    },
});

export const {
    resetErrorState,
    resetUserState,
    setPasswordError,
    setPasswordChangeSuccess,
    setUserEmailForVerification,
} = authSlice.actions;
