import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import LoginComponent from "../components/login_page_components/LoginComponent";
import RegisterComponent from "../components/login_page_components/RegisterComponent";
import { useDispatch, useSelector } from "react-redux";
import { resetErrorState } from "../features/authentication/authSlice";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  margin: "auto",
  minHeight: "calc(100vh - 55px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    padding: "30px",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "40.625rem",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(6),
}));

const ActionBtnsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(4),
  boxSizing: "border-box",
}));

const ActionBtn = styled(Typography)(({ theme, currentState }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
  textTransform: "uppercase",
  cursor: "pointer",
  color: currentState ? "#00000" : "#767676",
  outline: "none",
  position: "relative",
  borderBottom: currentState && "2x solid #000",
  transition:
    "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",
  "&:hover": {
    color: "#000000",
    "&::after": {
      width: "100%", // Expand width to the right based on text width
    },
  },
  "&::after": {
    content: `''`,
    display: "block",
    position: "absolute",
    bottom: 0,
    left: 0, // Adjusted to start from the left edge of the button
    width: currentState ? "100%" : 0,
    height: "2px",
    backgroundColor: "#000000",
    transition: "width 0.36s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  },
}));

function LoginPage() {
  const { passwordChangeSuccess, setPasswordChangeSuccess } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const [currentModel, setCurrentModel] = useState("login");

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={passwordChangeSuccess}
        autoHideDuration={5000}
        sx={{
          "&.MuiSnackbar-root": {
            top: "100px",
          },
        }}
      >
        <Alert
          onClose={() => {
            dispatch(setPasswordChangeSuccess(false));
          }}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Your New Password has ben set successfully.
        </Alert>
      </Snackbar>
      <Container>
        <InnerContainer>
          <ActionBtnsContainer>
            <ActionBtn
              onClick={() => {
                setCurrentModel("login");
                dispatch(resetErrorState());
              }}
              currentState={currentModel === "login"}
            >
              Login
            </ActionBtn>
            <ActionBtn
              onClick={() => {
                setCurrentModel("register");
                dispatch(resetErrorState());
              }}
              currentState={currentModel === "register"}
            >
              Register
            </ActionBtn>
          </ActionBtnsContainer>
          {currentModel === "login" ? (
            <LoginComponent />
          ) : (
            <RegisterComponent />
          )}
        </InnerContainer>
      </Container>
    </>
  );
}

export default LoginPage;
