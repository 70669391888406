import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from '../features/authentication/authSlice';
import { ordersSlice } from '../features/orders/ordersSlice';
import { productsSlice } from '../features/products/productsSlice';
import { addressSlice } from '../features/address/addressSlice';


export const store = configureStore({
    reducer: {
        user: authSlice.reducer,
        orders: ordersSlice.reducer,
        products: productsSlice.reducer,
        address: addressSlice.reducer
    },
});
