import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CheckIcon from "../../images/check-icon.json";
import Lottie from "react-lottie";

const Container = styled(Box)(({ theme }) => ({
  width: "60%",
  margin: "auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(4.5),
  width: "100%",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "36px",
  lineHeight: "42px",
  color: theme.palette.primary.main,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
    lineHeight: "32px",
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#767676",
}));

const OrderInfoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  border: "2px dashed #767676",
  padding: theme.spacing(5),
  display: "flex",
  gap: "1rem",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const OrderItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1),
  flexGrow: 1,
}));

const OrderInfoHeaderText = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  color: "#767676",
  fontSize: "14px",
  lineHeight: "24px",
}));

const OrderInfoText = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  color: "#000",
  fontSize: "16px",
  lineHeight: "27px",
}));

const OrderDetailsComponent = styled(Box)(({ theme }) => ({
  width: "100%",
  border: "1px solid #222",
  marginBottom: "1.25rem",
  padding: theme.spacing(5),
  boxSizing: "border-box",
}));

const OrderDetailsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(2),
}));

const TableCellComponent = styled(TableCell)(({ theme }) => ({
  padding: "14px 0px",
}));

const PriceTableCellComponent = styled(TableCell)(({ theme, lastEle }) => ({
  borderBottom: lastEle ? "1px solid rgba(224, 224, 224, 1)" : "none",
  padding: "14px 0px",
}));

const Text = styled(Typography)(({ theme, rightText }) => ({
  fontSize: "14px",
  fontWeight: 500,
  color: theme.palette.primary.main,
  textAlign: rightText ? "right" : "left",
}));

const SubText = styled(Typography)(({ theme, rightText }) => ({
  fontSize: "14px",
  fontWeight: 500,
  color: "#767676",
  textAlign: rightText ? "right" : "left",
}));

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: CheckIcon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function ConfirmOrderComponent() {
  return (
    <Container>
      <InnerContainer>
        <HeaderContainer>
          <Lottie options={defaultOptions} height={100} width={100} />
          <HeaderTitle>Your order is completed!</HeaderTitle>
          <HeaderText>Thank you. Your order has been received.</HeaderText>
        </HeaderContainer>
        <OrderInfoContainer>
          <OrderItem>
            <OrderInfoHeaderText>Order Number</OrderInfoHeaderText>
            <OrderInfoText>13119</OrderInfoText>
          </OrderItem>
          <OrderItem>
            <OrderInfoHeaderText>Date</OrderInfoHeaderText>
            <OrderInfoText>27/10/2023</OrderInfoText>
          </OrderItem>
          <OrderItem>
            <OrderInfoHeaderText>Total</OrderInfoHeaderText>
            <OrderInfoText>$81.40</OrderInfoText>
          </OrderItem>
          <OrderItem>
            <OrderInfoHeaderText>Payment Method</OrderInfoHeaderText>
            <OrderInfoText>Direct Bank Transfer</OrderInfoText>
          </OrderItem>
        </OrderInfoContainer>
        <OrderDetailsComponent>
          <OrderDetailsTitle>ORDER DETAILS</OrderDetailsTitle>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellComponent>
                    <Text>PRODUCT</Text>
                  </TableCellComponent>
                  <TableCellComponent>
                    <Text rightText={true}>SUBTOTAL</Text>
                  </TableCellComponent>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <PriceTableCellComponent>
                    <SubText>Zessi Dresses x 2</SubText>
                  </PriceTableCellComponent>
                  <PriceTableCellComponent>
                    <SubText rightText={true}>$32.50</SubText>
                  </PriceTableCellComponent>
                </TableRow>
                <TableRow>
                  <PriceTableCellComponent lastEle={true}>
                    <SubText>Kirby T-Shirt</SubText>
                  </PriceTableCellComponent>
                  <PriceTableCellComponent lastEle={true}>
                    <SubText rightText={true}>$29.90</SubText>
                  </PriceTableCellComponent>
                </TableRow>
                <TableRow>
                  <TableCellComponent>
                    <Text>SUBTOTAL</Text>
                  </TableCellComponent>
                  <TableCellComponent>
                    <Text rightText={true}>$62.40</Text>
                  </TableCellComponent>
                </TableRow>
                <TableRow>
                  <TableCellComponent>
                    <Text>SHIPPING</Text>
                  </TableCellComponent>
                  <TableCellComponent>
                    <Text rightText={true}>Free shipping</Text>
                  </TableCellComponent>
                </TableRow>
                <TableRow>
                  <TableCellComponent>
                    <Text>VAT</Text>
                  </TableCellComponent>
                  <TableCellComponent>
                    <Text rightText={true}>$19</Text>
                  </TableCellComponent>
                </TableRow>
                <TableRow>
                  <TableCellComponent>
                    <Text>TOTAL</Text>
                  </TableCellComponent>
                  <TableCellComponent>
                    <Text rightText={true}>$81.40</Text>
                  </TableCellComponent>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </OrderDetailsComponent>
      </InnerContainer>
    </Container>
  );
}

export default ConfirmOrderComponent;
