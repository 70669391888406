import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

function ProductImgCarousel({ images }) {
  const ImgContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
  }));

  const ImgEle = styled("img")(({ theme }) => ({
    width: "100%",
    height: "auto",
  }));

  return (
    <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
      {images.map((img) => (
        <SwiperSlide>
          <ImgContainer>
            <ImgEle src={img.image} alt={img.alt} />
          </ImgContainer>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default ProductImgCarousel;
