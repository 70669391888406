import logo from "../images/site-logo.svg";
import logo_black from "../images/site-logo-black.svg";
import footer_logo from "../images/footer-log.svg";
import pic1 from "../images/new-images/new-img1.jpg";
import pic2 from "../images/new-images/new-img2.jpg";
import pic3 from "../images/new-images/new-img3.jpg";
import pic4 from "../images/new-images/new-img4.jpg";
import pic6 from "../images/new-images/new-img2.jpg";
// import pic7 from "../images/new-images/new-img4.jpg";
// import pic8 from "../images/new-images/new-img3.jpg";

export const navbar_context = {
  topbar_text: "Free shipping on orders  100$+",
  site_logo: logo,
  site_logo_black: logo_black,
  noproduct_found_text: "No results found!",
  menu_links: [
    {
      menu: "Home",
      path: "/",
    },
    {
      menu: "Shop",
      path: "/shop",
    },
    {
      menu: "About",
      path: "/about-us",
    },
    {
      menu: "Faqs",
      path: "/faqs",
    },
    {
      menu: "Contact",
      path: "/contact_us",
    },
  ],
  sidebar_menu_links: [
    {
      menu: "Shops All",
      path: "/shop",
    },
    {
      menu: "Running",
      path: "/#",
    },
    {
      menu: "Yoga",
      path: "/#",
    },
    {
      menu: "Gym",
      path: "/#",
    },
    {
      menu: "Sports Bra",
      path: "/#",
    },
  ],
};

export const footer_context = {
  footerLogo: footer_logo,
  join_btn_text: "Join",
  column_one_text: {
    title: "CURRENCY",
  },
  column_two_text: {
    title: "COMPANY",
    links: [
      {
        link_name: "About Us",
        link: "/about-us",
      },
      {
        link_name: "Careers",
        link: "/#",
      },
      {
        link_name: "Affiliates",
        link: "/#",
      },
      {
        link_name: "Blog",
        link: "/#",
      },
      {
        link_name: "Contact Us",
        link: "/#",
      },
    ],
  },
  column_three_text: {
    title: "SHOP",
    links: [
      {
        link_name: "New Arrivals",
        link: "/#",
      },
      {
        link_name: "Leggings",
        link: "/#",
      },
      {
        link_name: "Shorts",
        link: "/#",
      },
      {
        link_name: "Bra",
        link: "/#",
      },
      {
        link_name: "Shop All",
        link: "/#",
      },
    ],
  },
  column_four_text: {
    title: "HELP",
    links: [
      {
        link_name: "Track Order",
        link: "/#",
      },
      {
        link_name: "Customer Service",
        link: "/#",
      },
      {
        link_name: "Legal & Privacy",
        link: "/#",
      },
      {
        link_name: "Contact",
        link: "/#",
      },
      {
        link_name: "Gift Card",
        link: "/#",
      },
    ],
  },
  column_five_text: {
    title: "ACCOUNT",
    links: [
      {
        link_name: "My Account",
        link: "/users/myprofile",
      },
      {
        link_name: "Orders",
        link: "/orders",
      },
      {
        link_name: "Edit Profile",
        link: "/users/myprofile",
      },
      {
        link_name: "Addresses",
        link: "/address",
      },
      {
        link_name: "Sitemap",
        link: "/#",
      },
    ],
  },
};

export const login_context = {
  title: "LOGIN",
  input_text_one: "Username or Email",
  input_text_two: "Password",
  linkbtn_text_one: "Lost your password?",
  loginbtn_text: "Log in",
  text: "No account yet? ",
  linkbtn_text_two: "Create an account",
  remember_me_text: "Remember me",
};

export const changepassword_context = {
  title: "Change Password",
  old_pass_text: "Old Password",
  new_pass_text: "New Password",
  confirm_new_pass_text: "Confirm New Password",
};

export const trackorder_context = {
  title: "Track Your Order",
  input_text_one: "Order Number",
  input_text_two: "Email",
  input_text_three: "Shipping Zip Code",
  link_one: "Where is my order number?",
  link_two: "Privacy Policy",
  btn_text: "Check Status",
};

export const register_context = {
  input_text_one: "First Name",
  input_text_two: "Last Name",
  input_text_three: "Email Address",
  input_text_four: "Password",
  policy_text:
    "Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.",
  btn_text: "Register",
  linkbtn_text: "Already have an account?",
  linkbtn_text_two: "Login",
};

export const cartbar_context = {
  title: "SHOPPING BAG",
  cartData: [
    // {
    //   img: pic1,
    //   title: "Long Sleeve Welt",
    //   category: "T-shirt",
    //   qty: 1,
    //   price: "85.00",
    //   color: "Yellow",
    //   size: "L",
    // },
    // {
    //   img: pic2,
    //   title: "Summer Dress Women Short Sleeve",
    //   category: "Dressess",
    //   qty: 2,
    //   price: "50.00",
    //   color: "Red",
    //   size: "XL",
    // },
    // {
    //   img: pic3,
    //   title: "Women's Sleeve Fully Beaded Gown",
    //   category: "Dressess",
    //   qty: 4,
    //   price: "65.00",
    //   color: "Green",
    //   size: "M",
    // },
    // {
    //   img: pic4,
    //   title: "Men's 3-Pack V-Neck T-Shirt",
    //   category: "T-shirt",
    //   qty: 2,
    //   price: "55.00",
    //   color: "Grey",
    //   size: "XXL",
    // },
    // {
    //   img: pic1,
    //   title: "Dresses Fully Beaded Gown",
    //   category: "Dressess",
    //   qty: 1,
    //   price: "95.00",
    //   color: "Grey",
    //   size: "XXL",
    // },
    // {
    //   img: pic6,
    //   title: "Women's High-Waist Jean",
    //   category: "T-shirt",
    //   qty: 2,
    //   price: "15.00",
    //   color: "Grey",
    //   size: "XXL",
    // },
    // {
    //   img: pic7,
    //   title: "Summer Dress Women Short Sleeve",
    //   category: "Dressess",
    //   qty: 2,
    //   price: "50.00",
    //   color: "Red",
    //   size: "XL",
    // },
    // {
    //   img: pic8,
    //   title: "Women's Sleeve Fully Beaded Gown",
    //   category: "T-shirt",
    //   qty: 4,
    //   price: "65.00",
    //   color: "Green",
    //   size: "M",
    // },
  ],
  subtotal: "SUBTOTAL:",
  viewcart_btn_text: "View Cart",
  checkout_btn_text: "Checkout",
};

export const searchbar_context = {
  title: "WHAT ARE YOU LOOKING FOR?",
  search_text: "SEARCH PRODUCTS....",
  links_title: "QUICKLINKS",
  noproduct_found_text: "No results found!",
  links: ["Accessories", "Men", "Dresses", "Women", "Shop All"],
};

export const products = [
  {
    img: [pic3, pic2, pic3, pic4, pic6],
    title: "Flowing Long Sleeve Top",
    old_price: "79.00",
    sale_price: "55.30",
    off_percent: "30",
    offer_percentage: "20",
    rating: 5,
    path: "/products",
  },
  {
    img: [pic4, pic2, pic3, pic4, pic6],
    title: "Cotton Sateen Shorts",
    old_price: "69.00",
    sale_price: "41.40",
    off_percent: "40",
    offer_percentage: "20",
    rating: 5,
    path: "/products",
  },
  {
    img: [pic6, pic2, pic3, pic4, pic6],
    title: "Original Straight Fit Jeans",
    old_price: "89.00",
    sale_price: "71.20",
    off_percent: "20",
    offer_percentage: null,
    rating: 4.5,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Original Skinny Fit Jeans",
    old_price: "89.00",
    sale_price: "71.20",
    off_percent: "20",
    offer_percentage: null,
    rating: null,
    path: "/products",
  },
  {
    img: [pic3, pic2, pic3, pic4, pic6],
    title: "Striped Crewneck Sweater",
    old_price: "59.00",
    sale_price: "35.40",
    off_percent: "40",
    offer_percentage: "15",
    rating: 4.2,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Denim Jacket",
    old_price: "99.00",
    sale_price: "69.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.8,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Printed Chiffon Dress",
    old_price: "129.00",
    sale_price: "90.30",
    off_percent: "30",
    offer_percentage: "10",
    rating: 4.6,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Knit Cardigan",
    old_price: "79.00",
    sale_price: "47.40",
    off_percent: "40",
    offer_percentage: "20",
    rating: 4.4,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Slim Fit Trousers",
    old_price: "69.00",
    sale_price: "55.20",
    off_percent: "20",
    offer_percentage: null,
    rating: 4.2,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Floral Print Blouse",
    old_price: "49.00",
    sale_price: "34.30",
    off_percent: "30",
    offer_percentage: "15",
    rating: 4.7,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "V-Neck Sweater",
    old_price: "39.00",
    sale_price: "27.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.5,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Pleated Midi Skirt",
    old_price: "59.00",
    sale_price: "41.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.3,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Linen Button-Up Shirt",
    old_price: "69.00",
    sale_price: "41.40",
    off_percent: "40",
    offer_percentage: "20",
    rating: 4.6,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "High-Waisted Jeans",
    old_price: "89.00",
    sale_price: "71.20",
    off_percent: "20",
    offer_percentage: null,
    rating: 4.4,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Turtleneck Sweater Dress",
    old_price: "79.00",
    sale_price: "55.30",
    off_percent: "30",
    offer_percentage: "10",
    rating: 4.7,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Embroidered Denim Shorts",
    old_price: "49.00",
    sale_price: "29.40",
    off_percent: "40",
    offer_percentage: null,
    rating: 4.2,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Leather Crossbody Bag",
    old_price: "99.00",
    sale_price: "69.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.8,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Classic Button-Down Shirt",
    old_price: "59.00",
    sale_price: "41.30",
    off_percent: "30",
    offer_percentage: "15",
    rating: 4.5,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Cropped Wide-Leg Pants",
    old_price: "69.00",
    sale_price: "55.20",
    off_percent: "20",
    offer_percentage: null,
    rating: 4.3,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Hooded Sweatshirt",
    old_price: "49.00",
    sale_price: "34.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.6,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Ruffled Wrap Dress",
    old_price: "79.00",
    sale_price: "55.30",
    off_percent: "30",
    offer_percentage: "10",
    rating: 4.7,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Pinstripe Blazer",
    old_price: "89.00",
    sale_price: "62.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.4,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Embroidered Cotton Dress",
    old_price: "129.00",
    sale_price: "77.40",
    off_percent: "40",
    offer_percentage: "20",
    rating: 4.8,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Satin Midi Skirt",
    old_price: "69.00",
    sale_price: "48.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.5,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Faux Leather Leggings",
    old_price: "59.00",
    sale_price: "41.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.3,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Knit Beanie Hat",
    old_price: "19.00",
    sale_price: "13.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.6,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Quilted Crossbody Bag",
    old_price: "79.00",
    sale_price: "47.40",
    off_percent: "40",
    offer_percentage: "15",
    rating: 4.2,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Cropped Denim Jacket",
    old_price: "89.00",
    sale_price: "62.30",
    off_percent: "30",
    offer_percentage: null,
    rating: 4.7,
    path: "/products",
  },
  {
    img: [pic1, pic2, pic3, pic4, pic6],
    title: "Button-Front Midi Dress",
    old_price: "99.00",
    sale_price: "69.30",
    off_percent: "30",
    offer_percentage: "10",
    rating: 4.4,
    path: "/products",
  },
];

export const sidebarNavlinks = [
  {
    menu: "My Account",
    path: "/users/myprofile",
  },
  {
    menu: "Address",
    path: "/address",
  },
  {
    menu: "Order",
    path: "/orders",
  },
  {
    menu: "Change Password",
    path: "/change-password",
  },
];

export const orders_list = [
  {
    id: "#KK0520241716031577",
    img: pic1,
    date: "May 18, 2024",
    items: 1,
    total: 62,
    payment_mode: "Bank Transfer",
  },
  {
    id: "#KK0520241716031542",
    img: pic2,
    date: "May 18, 2024",
    items: 1,
    total: 62,
    payment_mode: "Bank Transfer",
  },
  {
    id: "#KK0520241715687166",
    img: pic3,
    date: "May 18, 2024",
    items: 1,
    total: 62,
    payment_mode: "Bank Transfer",
  },
  {
    id: "#KK0520241715687134",
    img: pic6,
    date: "May 18, 2024",
    items: 1,
    total: 62,
    payment_mode: "Bank Transfer",
  },
  {
    id: "#KK0520241715687122",
    img: pic4,
    date: "May 18, 2024",
    items: 1,
    total: 62,
    payment_mode: "Bank Transfer",
  },
];

export const faq_context = {
  title: "FREQUENTLY ASKED QUESTIONS",
  faqs_data: [
    {
      title: "Orders",
      faqs: [
        {
          id: "e2a3387d-227d-40ed-9526-a6327bad6b9c",
          ques: "Bring of had which their whose you're it own?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
        {
          id: "cf4293af-1426-4d9b-9ff1-f53c32ee2457",
          ques: "Over shall air can't subdue fly divide him?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
        {
          id: "24a7be18-c735-4433-9de3-d202f0803700",
          ques: "Waters one you'll creeping?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
      ],
    },
    {
      title: "Shipping",
      faqs: [
        {
          id: "a8ca2564-593b-478c-8fae-40d6f51b5d7f",
          ques: "Bring of had which their whose you're it own?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
        {
          id: "10833e89-e18f-4594-b9f5-d40cd079ac96",
          ques: "Over shall air can't subdue fly divide him?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
        {
          id: "88853b2a-69ab-4754-b3e1-61f065a85339",
          ques: "Waters one you'll creeping?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
      ],
    },
    {
      title: "Payment",
      faqs: [
        {
          id: "186946b9-e624-4576-ab16-027ef8a243f7",
          ques: "Bring of had which their whose you're it own?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
        {
          id: "707133bb-161f-4e6e-9253-17b31ccfcf02",
          ques: "Over shall air can't subdue fly divide him?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
        {
          id: "4a47dc6b-08d1-40af-bed3-2d9cfb535748",
          ques: "Waters one you'll creeping?",
          answ: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        },
      ],
    },
  ],
};

export const contactus_page_context = {
  address_context: {
    title: "Head Office",
    co_name: "Kikani INC",
    address_line_one: "152027 Savannah Heights Dr. Austin,",
    address_line_two: "Texas - 78717",
    country: "United States",
    email: "info@kikaniinc.com",
  },
  form_context: {
    title: "Get In Touch",
    name_field_name: "Name",
    email_field_name: "Email Address",
    message_field_name: "Your Message",
    btn_text: "Submit",
  },
};
