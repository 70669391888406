import { Box, Card, Typography, styled } from "@mui/material";
import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation } from "swiper/modules";
import { useNavigate } from "react-router";
import BlankCard from "./BlankCard";

const Container = styled(Card)(({ theme }) => ({
  borderRadius: "0px",
  boxShadow: "none",
  cursor: "pointer",
  minHeight: "100%",
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  marginBottom: theme.spacing(1),
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ImgEle = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  color: theme.palette.primary.light,
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  color: "#000",
  lineHeight: "24px",
  display: "flex",
  gap: "4px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "20px",
  },
}));

const OldPriceSpan = styled("span")(({ theme }) => ({
  textDecoration: "line-through",
}));

const PriceSpan = styled("span")(({ theme }) => ({}));

const PercentSpan = styled("span")(({ theme }) => ({
  color: "#d93e3d",
}));

const OfferPercText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  color: "#d7373d",
  lineHeight: "24px",
  display: "flex",
  gap: "4px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "20px",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  padding: "0px 10px",
}));

function ProductsCard({ data, loading }) {
  const navigate = useNavigate();

  if (loading) {
    return <BlankCard />;
  }

  return (
    <Container>
      <ImgContainer>
        <Swiper
          pagination={true}
          loop={true}
          modules={[Navigation, Pagination]}
          className="mySwiper"
        >
          {data?.galleries.map((img, index) => (
            <SwiperSlide key={index}>
              <ImgEle
                src={img.image}
                alt={img.alt}
                onClick={() => {
                  navigate(`/shop/${data.slug}`);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </ImgContainer>
      <TextContainer
        onClick={() => {
          navigate(`/shop/${data.slug}`);
        }}
      >
        <Text sx={{ fontWeight: 600 }}>{data.name}</Text>
        <PriceText>
          {data.old_price && <OldPriceSpan>${data.old_price} </OldPriceSpan>}
          <PriceSpan>
            {data.price[0].currencies_symbol}
            {data.price[0].value}
          </PriceSpan>
          {data.off_percent && (
            <PercentSpan> {data.off_percent}% off</PercentSpan>
          )}
        </PriceText>
        {data.offer_percentage && (
          <OfferPercText>Extra {data.offer_percentage}% off</OfferPercText>
        )}
      </TextContainer>
    </Container>
  );
}

export default ProductsCard;
