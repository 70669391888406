import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const ImgContainer = styled(Box)(({ theme }) => ({
  height: "140px",
  width: "100px",
  overflow: "hidden",
  display: "flex",
  //   [theme.breakpoints.down("sm")]: {
  //     width: "150px",
  //   },
}));

const Image = styled("img")(({ theme }) => ({
  objectFit: "cover",
  width: "100%",
}));

const ProductDetailsCard = styled(Box)(({ theme }) => ({
  display: "flex",
}));

const ProductDetailsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  boxSizing: "border-box",
  border: "1px solid #00000020",
  borderBottom: "0px",
  display: "flex",
  justifyContent: "space-between",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  color: "#6c757d",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  textTransform: "capitalize",
}));

function OrderProductsCard({ data }) {
  return (
    <ProductDetailsContainer>
      <ProductDetailsCard>
        <ImgContainer>
          <Image
            src={data.product.galleries[0].image}
            alt={data.product.galleries[0].alt}
          />
        </ImgContainer>
        <Box sx={{ marginLeft: "1rem", flexGrow: 1 }}>
          <Text sx={{ marginBottom: "0px" }}>{data.product.name}</Text>
          {data.product.attributes.map((attribute, index) => {
            return (
              <SubText key={index}>
                {attribute.attribute_name} : {attribute.value_name}
              </SubText>
            );
          })}
          <SubText>Qty : {data.quantity}</SubText>
          <SubText>Price : ${data.unit_price}</SubText>
        </Box>
      </ProductDetailsCard>
      <Text>${data.subtotal}</Text>
    </ProductDetailsContainer>
  );
}

export default OrderProductsCard;
