import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CloseIcon from "../../images/icons/cross-icon-black.svg";
import { cartbar_context } from "../../utils/textUtils";
import theme from "../../utils/themes";
import CartItemCard from "./card_widgets/CartItemCard";
import { useNavigate } from "react-router";

const Container = styled(Box)(({ theme }) => ({
  width: "420px",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: "#E4E4E4",
  padding: `${theme.spacing(1)} 20px`,
  height: "55px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const Body = styled(Box)(({ theme }) => ({
  padding: "20px 20px",
  [theme.breakpoints.down("sm")]: {
    padding: "15px 20px",
  },
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  position: "relative",
  textTransform: "uppercase",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
  },
}));

const CloseBtnContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "18px",
  right: "14px",
  [theme.breakpoints.down("sm")]: {
    right: "20px",
  },
}));

const CartListingContainer = styled(Box)(({ theme }) => ({
  height: "calc(100dvh - 320px)",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),

  // Custom scroll bar styles
  "&::-webkit-scrollbar": {
    width: "8px", // Width of the scrollbar
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1", // Color of the track
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888", // Color of the thumb
    borderRadius: "4px", // Rounded corners
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555", // Color of the thumb on hover
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
}));

const ViewCartBtn = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  textAlign: "center",
  backgroundColor: "#E4E4E4",
  borderRadius: "0px",
  color: "#000",
  padding: "15px 30px",
  "&: hover": {
    color: "#000",
    backgroundColor: "#d7d7d7",
  },
}));

const CheckoutBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "0px",
  padding: "15px 30px",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
}));

const NoProductFoundTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "500",
  marginBottom: theme.spacing(1.5),
}));

const NoProductFoundText = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontWeight: "400",
  marginBottom: theme.spacing(1.5),
}));

const LoginBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  fontWeight: "600",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

function CartSideBar({ open, setOpen }) {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen();
  };

  const Icon = styled("img")(({ theme }) => ({
    width: "20px",
    height: "20px",
  }));

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={handleClose}>
      <Container>
        <Header>
          <HeaderTitle>
            {cartbar_context.title}{" "}
            {cartbar_context.cartData.length > 0 &&
              `${cartbar_context.cartData.length}`}
          </HeaderTitle>
          <CloseBtnContainer>
            <IconButton onClick={handleClose}>
              <Icon src={CloseIcon} alt="cross-icon" />
            </IconButton>
          </CloseBtnContainer>
        </Header>
        <Body>
          {cartbar_context.cartData.length > 0 ? (
            <>
              <CartListingContainer>
                {cartbar_context.cartData.map((data, index) => (
                  <CartItemCard data={data} key={index} />
                ))}
              </CartListingContainer>
              <Divider sx={{ margin: `${theme.spacing(2)} 0px` }} />
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ marginBottom: theme.spacing(2) }}
              >
                <Text>{cartbar_context.subtotal}</Text>
                <Text>$713.00</Text>
              </Stack>
              <Stack
                direction="column"
                justifyContent="space-between"
                spacing={2}
              >
                <ViewCartBtn>{cartbar_context.viewcart_btn_text}</ViewCartBtn>
                <CheckoutBtn>{cartbar_context.checkout_btn_text}</CheckoutBtn>
              </Stack>
            </>
          ) : (
            <>
              <NoProductFoundTitle>Shopping Bag is Empty</NoProductFoundTitle>
              <NoProductFoundText>
                Looks like you haven't added anything to your cart yet.
              </NoProductFoundText>
              <LoginBtn
                onClick={() => {
                  navigate("/shop");
                  handleClose();
                }}
              >
                Shop Now
              </LoginBtn>
            </>
          )}
        </Body>
      </Container>
    </SwipeableDrawer>
  );
}

export default CartSideBar;
