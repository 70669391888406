import {
  Avatar,
  Box,
  Divider,
  Rating,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import theme from "../../../utils/themes";

const Container = styled(Box)(({ theme, lastEle }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  borderBottom: lastEle ? "none" : "1px solid #e4e4e4",
  paddingBottom: theme.spacing(2),
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  width: "fit-content",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Description = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  padding: `0px ${theme.spacing(2)}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  rowGap: theme.spacing(1),
}));

const NameText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#000",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#767676",
  columnSpan: "14px",
}));

function ReveiwsCard({ data, index, array }) {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container lastEle={index === array.length - 1}>
      <ImgContainer>
        <Avatar sx={{ width: "50%", height: "auto" }} src={data.img} />
      </ImgContainer>
      <Description>
        <NameText>{data.name}</NameText>
        <Stack direction={matches ? "column" : "row"} spacing={1}>
          <Rating
            name="read-only"
            value={data.ratings}
            readOnly
            size="small"
            sx={{
              color: "#000",
            }}
          />
          {!matches && <Divider orientation="vertical" />}
          <Text
            sx={{
              [theme.breakpoints.down("sm")]: {
                fontSize: "small",
              },
            }}
          >
            {data.date}
          </Text>
        </Stack>
        <Text>{data.comment}</Text>
      </Description>
    </Container>
  );
}

export default ReveiwsCard;
