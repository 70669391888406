import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import { useNavigate } from "react-router";

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  backgroundClip: "border-box",
  boxSizing: "border-box",
  border: "1px solid #00000020",
  padding: theme.spacing(2),
  rowGap: theme.spacing(2),
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

const IconContainer = styled(Box)(({ theme }) => ({
  width: "35px",
  height: "35px",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "small",
  fontWeight: 400,
  color: "#000",
}));

const ViewBtn = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#000000",
  borderColor: "#000000",
  boxShadow: "none",
  borderRadius: "0px",
  textTransform: "capitalize",
  fontWeight: 500,
  "&: hover": {
    color: "#fff",
    backgroundColor: "#000000",
    borderColor: "#000000",
  },
}));

const Body = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
}));

const Image = styled("img")(({ theme }) => ({
  height: "auto",
  width: "100%",
  objectFit: "cover",
}));

const Description = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

function OrderCard({ data }) {
  const navigate = useNavigate();
  return (
    <Container>
      <Header>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <IconContainer>
                <CheckCircleRoundedIcon
                  sx={{ height: "100%", width: "100%" }}
                />
              </IconContainer>
              <Stack direction="column">
                <Title>Ordered</Title>
                <Text>{data.order_date}</Text>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Stack direction="row" justifyContent="flex-end">
              <ViewBtn
                variant="contained"
                startIcon={<RemoveRedEyeSharpIcon />}
                onClick={() => {
                  navigate(`/orders/${data.id}`);
                }}
              >
                View
              </ViewBtn>
            </Stack>
          </Grid>
        </Grid>
      </Header>
      <Body>
        <ImageContainer>
          <Image
            src={data?.items[0]?.product?.galleries[0]?.image}
            alt={data?.items[0]?.product?.galleries[0]?.alt}
          />
        </ImageContainer>
        <Description>
          <Title sx={{ fontWeight: 500 }}>#{data.order_number}</Title>
          <Text>Date: {data.order_date}</Text>
          <Text>Items: {data.items.length}</Text>
          <Text>Total: {data.total_amount}</Text>
          <Text>Payments: {data.payment_method}</Text>
        </Description>
      </Body>
    </Container>
  );
}

export default OrderCard;
