/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useRef, useEffect } from "react";
import OrderCard from "../components/orders_page_components/card_widgets/OrderCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "../features/orders/ordersSlice";
import loader from "../images/loader.gif";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { useNavigate } from "react-router";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "auto",
  minHeight: "calc(100vh - 55px)",
  backgroundColor: "#fff",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "45%",
  margin: "auto",
  padding: `${theme.spacing(5)} 0px`,
  [theme.breakpoints.between("sm", "md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const LoadingLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
}));

const NoProductFoundTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "500",
}));

const NoProductFoundText = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontWeight: "400",
  color: "#6c757d",
  marginBottom: "4px!important",
}));

const ShopMoreBtn = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: 1.5,
  color: "#000000",
  backgroundColor: "#ffffff",
  border: "1px solid #000",
  borderRadius: 0,
  transition: "all 0.15s ease-in-out",
  fontSize: "0.875rem",
  padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`,
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000000",
    borderColor: "#000000",
  },
}));

function OrdersPageComponent() {
  const { status, order_list } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      dispatch(fetchOrders());
      hasFetched.current = true;
    }
  }, [dispatch]);
  return (
    <>
      {status === "loading" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <LoadingLogo src={loader} alt="site-loader" />
        </Backdrop>
      )}
      <Container>
        <InnerContainer>
          {order_list?.length > 0 ? (
            <Grid container spacing={3}>
              {order_list?.map((data, index, array) => {
                console.log(array);
                return (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    <OrderCard data={data} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Stack justifyContent="center" alignItems="center" spacing={1}>
              <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: "5rem" }} />
              <NoProductFoundTitle>No Order Found</NoProductFoundTitle>
              <NoProductFoundText>
                Looks like you haven't made your order yet
              </NoProductFoundText>
              <ShopMoreBtn
                onClick={() => {
                  navigate("/shop");
                }}
              >
                Go Shopping
              </ShopMoreBtn>
            </Stack>
          )}
        </InnerContainer>
      </Container>
    </>
  );
}

export default OrdersPageComponent;
