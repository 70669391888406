import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "150px",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ImageEle = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#000",
  fontSize: "16px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#767676",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

const SelectComponent = styled(Select)(({ theme }) => ({
  borderRadius: "0px",
  textAlign: "center",
  backgroundColor: "#fff",
  border: "1px solid #000",
  "&:hover": {
    backgroundColor: "#fff",
    borderBottom: "0px",
  },
  "&::before": {
    border: "0px",
  },
  "&::after": {
    border: "0px",
  },
  ".MuiSelect-root": {
    backgroundColor: "blue",
    "&:hover": {
      backgroundColor: "#fff",
      borderBottom: "0px",
    },
    "& ::before": {
      backgroundColor: "orange",
      border: "0px",
    },
  },
  ".MuiSelect-filled": {
    backgroundColor: "#fff",
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
}));

const MenuItemComponent = styled(MenuItem)(({ theme }) => ({
  justifyContent: "center",
}));

const CloseBtnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

const TextContainer = styled(Box)(({ theme }) => ({
  marginTop: "2px",
  marginBottom: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  position: "relative",
  rowGap: theme.spacing(1),
}));

const TableCellComponent = styled(TableCell)(({ theme, firstEle }) => ({
  width: firstEle ? "60%" : "20%",
}));

const quantity = [1, 2, 3, 4, 5, 6];

function TableCard({ data, index }) {
  return (
    <TableRow>
      <TableCellComponent firstEle={index === 0}>
        <Stack direction="row" sx={{ width: "fit-content" }} spacing={3.5}>
          <ImageContainer>
            <ImageEle src={data.img} />
          </ImageContainer>
          <TextContainer>
            <Title>{data.title}</Title>
            <span>
              <SubText>Color: {data.color}</SubText>
              <SubText>Size: {data.size}</SubText>
            </span>
          </TextContainer>
        </Stack>
      </TableCellComponent>
      <TableCellComponent>
        <SubText sx={{ fontSize: "16px" }}>${data.price}</SubText>
      </TableCellComponent>
      <TableCellComponent>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="demo-simple-select-label">Qty</InputLabel>
          <SelectComponent defaultValue={1} label="Qty">
            {quantity.map((qty) => (
              <MenuItemComponent value={qty}>{qty}</MenuItemComponent>
            ))}
          </SelectComponent>
        </FormControl>
      </TableCellComponent>
      <TableCellComponent>
        <SubText sx={{ color: "#000", fontSize: "16px" }}>
          $ {data.price * data.qty}
        </SubText>
      </TableCellComponent>
      <TableCellComponent>
        <CloseBtnContainer>
          <IconButton>
            <CloseSharpIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </CloseBtnContainer>
      </TableCellComponent>
    </TableRow>
  );
}

export default TableCard;
