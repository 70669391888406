import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import ShippingForm from "./card_widgets/ShippingForm";
import OrderDetailsComponent from "./card_widgets/OrderDetailsComponent";
import { Link } from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const RightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
  position: "sticky",
  top: 0,
}));

const Input = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  borderColor: "#e4e4e4",
}));

const ApplyCouponBtn = styled(Button)(({ theme }) => ({
  borderRadius: "0px",
}));

const PaymentMethodContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #e4e4e4",
  maxWidth: "100%",
  padding: theme.spacing(4),
  boxSizing: "border-box",
}));

const PolicyText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "24px",
  color: theme.palette.primary,
  margin: "18px 0px",
}));

const LinkComponent = styled(Link)(({ theme }) => ({
  color: "#c32929",
  textDecoration: "none",
}));

const PlaceOrderBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const PaymentDesc = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 400,
  marginLeft: theme.spacing(4),
}));

const ShippingAndCheckoutComponent = ({ next }) => {
  const [inputDetails, setInputDetails] = useState({
    coupon: "",
    payment_method: "Direct bank transfer",
  });

  return (
    <Container>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={8}>
          <ShippingForm />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <RightContainer>
            <OrderDetailsComponent />
            <Input
              placeholder="COUPON CODE"
              endAdornment={
                <InputAdornment>
                  <ApplyCouponBtn>APPLY COUPON</ApplyCouponBtn>
                </InputAdornment>
              }
            />
            <PaymentMethodContainer>
              <FormControl fullWidth>
                <RadioGroup
                  aria-labelledby="payment-radio-buttons-group-label"
                  defaultValue="Direct bank transfer"
                  name="radio-buttons-group"
                  value={inputDetails.payment_method}
                  onChange={(event) => {
                    setInputDetails({
                      ...inputDetails,
                      payment_method: event.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="Direct bank transfer"
                    control={<Radio />}
                    label="Direct bank transfer"
                  />
                  {inputDetails.payment_method === "Direct bank transfer" && (
                    <PaymentDesc>
                      Make your payment directly into our bank account. Please
                      use your Order ID as the payment reference.Your order will
                      not be shipped until the funds have cleared in our
                      account.
                    </PaymentDesc>
                  )}
                  <FormControlLabel
                    value="Check payments"
                    control={<Radio />}
                    label="Check payments"
                  />
                  {inputDetails.payment_method === "Check payments" && (
                    <PaymentDesc>
                      Phasellus sed volutpat orci. Fusce eget lore mauris
                      vehicula elementum gravida nec dui. Aenean aliquam varius
                      ipsum, non ultricies tellus sodales eu. Donec dignissim
                      viverra nunc, ut aliquet magna posuere eget.
                    </PaymentDesc>
                  )}
                  <FormControlLabel
                    value="Cash on delivery"
                    control={<Radio />}
                    label="Cash on delivery"
                  />
                  {inputDetails.payment_method === "Cash on delivery" && (
                    <PaymentDesc>
                      Phasellus sed volutpat orci. Fusce eget lore mauris
                      vehicula elementum gravida nec dui. Aenean aliquam varius
                      ipsum, non ultricies tellus sodales eu. Donec dignissim
                      viverra nunc, ut aliquet magna posuere eget.
                    </PaymentDesc>
                  )}
                  <FormControlLabel
                    value="Paypal"
                    control={<Radio />}
                    label="Paypal"
                  />
                  {inputDetails.payment_method === "Paypal" && (
                    <PaymentDesc>
                      Phasellus sed volutpat orci. Fusce eget lore mauris
                      vehicula elementum gravida nec dui. Aenean aliquam varius
                      ipsum, non ultricies tellus sodales eu. Donec dignissim
                      viverra nunc, ut aliquet magna posuere eget.
                    </PaymentDesc>
                  )}
                </RadioGroup>
              </FormControl>
              <PolicyText>
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our <LinkComponent>privacy policy</LinkComponent>.
              </PolicyText>
            </PaymentMethodContainer>
            <PlaceOrderBtn onClick={next}>Place Order</PlaceOrderBtn>
          </RightContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShippingAndCheckoutComponent;
