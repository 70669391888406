import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { contactus_page_context } from "../utils/textUtils";
import ContactFormComponent from "../components/contactus_page_components/ContactFormComponent";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  margin: "auto",
  padding: `${theme.spacing(10)} 0px`,
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(8),
  [theme.breakpoints.down("md")]: {
    width: "90%",
    padding: `${theme.spacing(5)} 0px`,
  },
}));

const AddressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "26px",
  lineHeight: "32px",
  color: "#000",
}));

const CoNameText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "27px",
  color: "#000",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "27px",
  color: "#000",
}));

function ContactUsPage() {
  const { address_context } = contactus_page_context;

  return (
    <Container>
      <InnerContainer>
        <AddressContainer>
          <Title>{address_context.title}</Title>
          <CoNameText>{address_context.co_name}</CoNameText>
          <Stack spacing={0.5}>
            <Text>{address_context.address_line_one}</Text>
            <Text>{address_context.address_line_two}</Text>
            <Text>{address_context.country}</Text>
          </Stack>
          <Text>{address_context.email}</Text>
        </AddressContainer>
        <ContactFormComponent />
      </InnerContainer>
    </Container>
  );
}

export default ContactUsPage;
