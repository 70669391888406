import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const Form = styled(Box)(({ theme }) => ({}));

const SelectComponent = styled(Select)(({ theme }) => ({
  borderRadius: "0px",
  backgroundColor: "#fff",
  border: "1px solid #000",
  "&:hover": {
    backgroundColor: "#fff",
    borderBottom: "0px",
  },
  "&::before": {
    border: "0px",
  },
  "&::after": {
    border: "0px",
  },
  ".MuiSelect-root": {
    backgroundColor: "blue",
    "&:hover": {
      backgroundColor: "#fff",
      borderBottom: "0px",
    },
    "& ::before": {
      backgroundColor: "orange",
      border: "0px",
    },
  },
  ".MuiSelect-filled": {
    backgroundColor: "#fff",
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
}));

const MenuItemComponent = styled(MenuItem)(({ theme }) => ({
  justifyContent: "flex-start",
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "28px",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    lineHeight: "24px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));

const quantity = [
  "Address 1",
  "Address 2",
  "Address 3",
  "Address 4",
  "Address 5",
  "Address 6",
];

function ShippingForm() {
  const [isShippingAddress, setIsShippingAddress] = useState(false);

  return (
    <Form>
      <Title>BILLING ADDRESS</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          {" "}
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Address
            </InputLabel>
            <SelectComponent defaultValue={1} label="Qty">
              {quantity.map((qty) => (
                <MenuItemComponent value={qty}>{qty}</MenuItemComponent>
              ))}
            </SelectComponent>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            name=""
            value=""
            onChange={() => {}}
            label="First Name"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            name=""
            value=""
            onChange={() => {}}
            label="Last Name"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            name=""
            value=""
            onChange={() => {}}
            label="Address Line 1"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            name=""
            value=""
            onChange={() => {}}
            label="Address Line 2"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            name=""
            value=""
            onChange={() => {}}
            label="Country"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            name=""
            value=""
            onChange={() => {}}
            label="Zip Code"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            name=""
            value=""
            onChange={() => {}}
            label="Order Notes (Optional)"
            type="text"
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <FormGroup sx={{ margin: "15px 0px" }}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              onChange={(event) => {
                if (!event.target.checked) {
                  setIsShippingAddress(true);
                } else {
                  setIsShippingAddress(false);
                }
              }}
            />
          }
          label="Shipping Address (Same as Billing)"
        />
      </FormGroup>
      {isShippingAddress && (
        <>
          <Title>SHIPPING ADDRESS</Title>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              {" "}
              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Address
                </InputLabel>
                <SelectComponent defaultValue={1} label="Qty">
                  {quantity.map((qty) => (
                    <MenuItemComponent value={qty}>{qty}</MenuItemComponent>
                  ))}
                </SelectComponent>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name=""
                value=""
                onChange={() => {}}
                label="First Name"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name=""
                value=""
                onChange={() => {}}
                label="Last Name"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name=""
                value=""
                onChange={() => {}}
                label="Address Line 1"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name=""
                value=""
                onChange={() => {}}
                label="Address Line 2"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                name=""
                value=""
                onChange={() => {}}
                label="Country"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                name=""
                value=""
                onChange={() => {}}
                label="Zip Code"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name=""
                value=""
                onChange={() => {}}
                label="Order Notes (Optional)"
                type="text"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
}

export default ShippingForm;
