import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";
import { useSelector } from "react-redux";
import theme from "../../utils/themes";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  backgroundColor: "#fff",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(4),
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  gap: theme.spacing(2),
}));

const LinkComponent = styled(Link)(({ theme, isOpened }) => ({
  color: isOpened ? "#000" : "#696969",
  textDecoration: "none",
  fontWeight: 400,
  fontSize: "14px",
}));

const ChipComponent = styled(Chip)(({ theme, isOpened }) => ({
  border: "1px solid #cccccc",
  paddingLeft: "15px",
  paddingRight: "12px",
  backgroundColor: "#fff",
  cursor: "pointer",
  "&.MuiChip-root": {
    flexDirection: "row-reverse",
  },
  "& .MuiChip-label": {
    padding: "0px",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#696969",
  lineHeight: "20px",
  fontWeight: 500,
}));

const SelectComponent = styled(Select)(({ theme }) => ({
  minWidth: "160px",
  borderRadius: "50px",
  height: "37px",
  fontSize: "14px",
}));

const LeftContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  gap: theme.spacing(2),
  flexWrap: "wrap",
}));

const RightContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "flex-end",
  alignItems: "center",
}));

const links = [
  {
    name: "Shop All",
    link: "/#",
  },
  {
    name: "Sports Bras",
    link: "/#",
  },
  {
    name: "Active Tops",
    link: "/#",
  },
  {
    name: "Active Jackets",
    link: "/#",
  },
  {
    name: "Active Bottoms",
    link: "/#",
  },
];

const filters = [
  {
    filter_name: "Categoty",
    icon: "arrow_down",
  },
  {
    filter_name: "Price",
    icon: "arrow_down",
  },
  {
    filter_name: "Color",
    icon: "arrow_down",
  },
  {
    filter_name: "Size",
    icon: "arrow_down",
  },
  {
    filter_name: "Collection",
    icon: "arrow_down",
  },
  {
    filter_name: "All Filters",
    icon: "settings_icon",
  },
];

function FiltersComponentV1({ setOpen, setFilters }) {
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { products } = useSelector((state) => state.products);

  return (
    <Container>
      <InnerContainer>
        {matches ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>{products.length} Items</Text>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              startIcon={<TuneSharpIcon />}
            >
              Filter & Sort
            </Button>
          </Stack>
        ) : (
          <>
            <LinksContainer>
              {links.map((link, index) => (
                <LinkComponent to={link.link} key={index}>
                  {link.name}
                </LinkComponent>
              ))}
            </LinksContainer>
            <Grid container>
              <Grid item md={6}>
                <LeftContainer>
                  {filters.map((filter, index) => {
                    return (
                      <ChipComponent
                        key={index}
                        label={filter.filter_name}
                        clickable={false}
                        onClick={() => {
                          setOpen(true);
                        }}
                        icon={
                          filter.icon === "arrow_down" ? (
                            <KeyboardArrowDownSharpIcon />
                          ) : (
                            <TuneSharpIcon sx={{ fontSize: "16px" }} />
                          )
                        }
                      />
                    );
                  })}
                </LeftContainer>
              </Grid>
              <Grid item md={6}>
                <RightContainer>
                  <Text>{products.length} Items</Text>
                  <Divider
                    sx={{
                      height: "15px",
                    }}
                    orientation="vertical"
                  />
                  <Text>Sort By</Text>
                  <SelectComponent
                    size="small"
                    IconComponent={KeyboardArrowDownSharpIcon}
                    native
                    defaultValue=""
                    id="grouped-native-select"
                    value={filters.sort}
                    onChange={(event) => {
                      // setFilters({
                      //   ...filters,
                      //   sort: event.target.value,
                      // });
                    }}
                  >
                    <option value="featured">Featured</option>
                    <option value="newest">Newest</option>
                    <option value="price-low-to-high">Price Low To High</option>
                    <option value="price-high-to-low">Price High To Low</option>
                    <option value="top-rated">Top Rated</option>
                  </SelectComponent>
                </RightContainer>
              </Grid>
            </Grid>
          </>
        )}
      </InnerContainer>
    </Container>
  );
}

export default FiltersComponentV1;
