import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { faq_context } from "../utils/textUtils";
import FaqCard from "../components/faqs_page_components/card_widgets/FaqCard";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  margin: "auto",
  padding: `${theme.spacing(5)} 0px`,
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "28px",
  lineHeight: "32px",
  fontWeight: "700",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
}));

const AccordionContainer = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0px`,
}));

function FaqPage() {
  return (
    <Container>
      <InnerContainer>
        <Title>{faq_context.title}</Title>
        <AccordionContainer>
          <Grid container spacing={3}>
            {faq_context.faqs_data.map((data, index) => {
              return (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  <FaqCard data={data} />
                </Grid>
              );
            })}
          </Grid>
        </AccordionContainer>
      </InnerContainer>
    </Container>
  );
}

export default FaqPage;
