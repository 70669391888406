import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function SalesPage() {
  return (
    <Container>
      <h1>This Sales Page is under construction</h1>
    </Container>
  );
}

export default SalesPage;
