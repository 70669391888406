import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const link = process.env.REACT_APP_BACKEND_API;

const refreshToken = async () => {
    try {
        const response = await axios.post(`${link}/users/api/token/refresh/`, {
            refresh: Cookies.get('refresh'),
        });
        const newAccessToken = response.data.access;
        Cookies.set('access', newAccessToken);
        return newAccessToken;
    } catch (error) {
        throw new Error('Failed to refresh token');
    }
};

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (_, thunkAPI) => {
    let token = Cookies.get('access');

    const makeRequest = async (token) => {
        return axios.get(`${link}/order/api/order_list/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };
    try {
        let response = await makeRequest(token);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            try {
                token = await refreshToken();
                const response = await makeRequest(token);
                return response.data;
            } catch (refreshError) {
                return thunkAPI.rejectWithValue(refreshError.response);
            }
        } else {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
});

const initialState = {
    status: "idle",
    error: null,
    order_list: []
}

export const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOrders.pending, (state) => {
            state.status = "loading";
        }).addCase(fetchOrders.fulfilled, (state, action) => {
            state.error = null;
            state.status = "success";
            console.log(action.payload);
            state.order_list = action.payload
        }).addCase(fetchOrders.rejected, (state, action) => {
            state.status = action.error.message;
            console.log(action.error.message);
            state.error = action.error.message;
        })
    }
})