import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  NativeSelect,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { footer_context } from "../../utils/textUtils";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  height: "auto",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "75%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  padding: `${theme.spacing(8)} 0px`,
  paddingBottom: 0,
  boxSizing: "border-box",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

const Logo = styled("img")(({ theme }) => ({
  width: "auto",
  height: "auto",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(6),
  width: "47rem",
  margin: "0 auto",
  textAlign: "center",
  padding: `0px ${theme.spacing(2)}`,
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: `0px`,
  },
}));

const Input = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: "#353535",
  color: "#fff",
  width: "100%",
  height: "100%",
  "&.MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const JoinBtn = styled(Button)(({ theme }) => ({
  backgroundColor: "#5c5c5c",
  color: "#fff",
  width: "100%",
  height: "100%",
  "&.MuiButton-root": {
    borderRadius: "0px",
  },
  "&:hover": {
    backgroundColor: "#5c5c5c",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Body = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: `${theme.spacing(4)} ${theme.spacing(1)}`,
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(4)} ${theme.spacing(0)}`,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#fff",
  marginBottom: "1.875rem",
}));

const LinkComponent = styled(Link)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "19px",
  color: "#fff",
  cursor: "pointer",
  textDecoration: "none",
  padding: "0.5em 0",
  marginTop: "3px",
  marginBottom: "2px",
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1),
}));

const NativeSelectComponent = styled(NativeSelect)(({ theme }) => ({
  backgroundColor: "#000",
  color: "#fff",
  marginBottom: theme.spacing(7),
  "& .MuiNativeSelect-root": {
    backgroundColor: "#000",
    color: "#fff",
  },
  "& .MuiSvgIcon-root": {
    backgroundColor: "#000",
    color: "#fff",
  },
}));

const Option = styled("option")(({ theme }) => ({
  backgroundColor: "#000 !important",
  color: "#fff",
}));

const IconButtonComponent = styled(IconButton)(({ theme }) => ({
  padding: "0px",
}));

function Footer() {
  return (
    <Container>
      <InnerContainer>
        <Header>
          <LogoContainer>
            <Logo src={footer_context.footerLogo} alt="footer-logo" />
          </LogoContainer>
          <Grid container spacing={2} component="form">
            <Grid item xs={12} sm={10} md={10}>
              <Input type="email" required placeholder="Your email address" />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <JoinBtn type="submit" contained>
                {footer_context.join_btn_text}
              </JoinBtn>
            </Grid>
          </Grid>
        </Header>
        <Body>
          <Grid container columns={{ xs: 12, sm: 12, md: 15 }}>
            <Grid item xs={12} sm={12} md={3}>
              <Body>
                <Text>{footer_context.column_one_text.title}</Text>
                <FormControl>
                  <NativeSelectComponent
                    defaultValue={30}
                    inputProps={{
                      name: "age",
                      id: "uncontrolled-native",
                    }}
                  >
                    <Option selected value="$USD">
                      $USD
                    </Option>
                    <Option value="₹INR">₹INR</Option>
                  </NativeSelectComponent>
                </FormControl>
                <Stack direction="row" spacing={3}>
                  <IconButtonComponent
                    onClick={() => {
                      window.location.href =
                        "https://www.facebook.com/people/Kikani/61555388055241/";
                    }}
                  >
                    <FaFacebookF style={{ color: "#fff", fontSize: "16px" }} />
                  </IconButtonComponent>
                  <IconButtonComponent onClick={() => {}}>
                    <FaXTwitter style={{ color: "#fff", fontSize: "16px" }} />
                  </IconButtonComponent>
                  <IconButtonComponent
                    onClick={() => {
                      window.location.href =
                        "https://www.instagram.com/kikani_inc/";
                    }}
                  >
                    <FaInstagram style={{ color: "#fff", fontSize: "16px" }} />
                  </IconButtonComponent>
                  <IconButtonComponent
                    onClick={() => {
                      window.location.href =
                        "https://www.youtube.com/@KikaniTexas";
                    }}
                  >
                    <FaYoutube style={{ color: "#fff", fontSize: "16px" }} />
                  </IconButtonComponent>
                  <IconButtonComponent
                    onClick={() => {
                      window.location.href =
                        "https://in.pinterest.com/kikanitexas/";
                    }}
                  >
                    <FaPinterest style={{ color: "#fff", fontSize: "16px" }} />
                  </IconButtonComponent>
                </Stack>
              </Body>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Body>
                <Text>{footer_context.column_two_text.title}</Text>
                <LinksContainer>
                  {footer_context.column_two_text.links.map((link, index) => (
                    <LinkComponent to={link.link} key={index}>
                      {link.link_name}
                    </LinkComponent>
                  ))}
                </LinksContainer>
              </Body>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Body>
                <Text>{footer_context.column_three_text.title}</Text>
                <LinksContainer>
                  {footer_context.column_three_text.links.map((link) => (
                    <LinkComponent to={link.link}>
                      {link.link_name}
                    </LinkComponent>
                  ))}
                </LinksContainer>
              </Body>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Body>
                <Text>{footer_context.column_four_text.title}</Text>
                <LinksContainer>
                  {footer_context.column_four_text.links.map((link) => (
                    <LinkComponent to={link.link}>
                      {link.link_name}
                    </LinkComponent>
                  ))}
                </LinksContainer>
              </Body>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Body>
                <Text>{footer_context.column_five_text.title}</Text>
                <LinksContainer>
                  {footer_context.column_five_text.links.map((link) => (
                    <LinkComponent to={link.link}>
                      {link.link_name}
                    </LinkComponent>
                  ))}
                </LinksContainer>
              </Body>
            </Grid>
          </Grid>
        </Body>
      </InnerContainer>
    </Container>
  );
}

export default Footer;
