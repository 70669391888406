import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../../utils/themes";

const Container = styled(Box)(({ theme }) => ({
  width: "auto",
  height: "auto",
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
}));

const ImgElement = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

const TextContainer = styled(Box)(({ theme }) => ({
  marginTop: "2px",
  marginBottom: "4px",
  direction: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#000",
  fontSize: "14px",
}));

const ProductTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#000",
  fontSize: "14px",
  width: "80%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#767676",
  fontSize: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

const CloseBtnContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "-6px",
  right: "0px",
}));

const RightContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

function CartItemCard({ data }) {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4} md={4}>
          <ImgContainer>
            <ImgElement src={data.img} alt="product_img" />
          </ImgContainer>
        </Grid>
        <Grid item xs={8} sm={8} md={8}>
          <RightContainer>
            <TextContainer>
              <ProductTitle>{data.title}</ProductTitle>
              <CloseBtnContainer>
                <IconButton>
                  <CloseIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              </CloseBtnContainer>
            </TextContainer>
            <TextContainer>
              <SubText>Color: {data.color}</SubText>
            </TextContainer>
            <TextContainer>
              <SubText>Size: {data.size}</SubText>
            </TextContainer>
            <TextContainer sx={{ marginTop: matches && "10px" }}>
              <Grid container alignItems="center">
                <Grid item xs={6} sm={6} md={6}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton>
                      <RemoveIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                    <Text>{data.qty}</Text>
                    <IconButton>
                      <AddIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Text sx={{ fontSize: "18px" }}>${data.price}</Text>
                  </Box>
                </Grid>
              </Grid>
            </TextContainer>
          </RightContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CartItemCard;
