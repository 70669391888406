import {
  Box,
  Grid,
  OutlinedInput,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import theme from "../../utils/themes";
import ReveiwsCard from "./card_widgets/ReveiwsCard";
import profile from "../../images/profile.svg";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#000",
  fontSize: "16px",
  lineHeight: "20px",
  marginBottom: theme.spacing(3),
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "22px",
  color: theme.palette.primary.main,
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1),
  padding: `${theme.spacing(1)} 0px`,
}));

const review = [
  {
    img: profile,
    name: "Rupesh Patel",
    ratings: 3,
    date: "April 25, 2024",
    comment: "good",
  },
  {
    img: profile,
    name: "Dhruv",
    ratings: 5,
    date: "April 25, 2024",
    comment: "comfortable",
  },
  {
    img: profile,
    name: "admin112 admin12",
    ratings: 3,
    date: "April 25, 2024",
    comment: "omg",
  },
  {
    img: profile,
    name: "admin2",
    ratings: 1,
    date: "April 25, 2024",
    comment: "comfortable",
  },
  {
    img: profile,
    name: "admin2",
    ratings: 2,
    date: "April 25, 2024",
    comment: "comfortable",
  },
];

function ReviewsComponent() {
  const [reviewDetails, setReviewDetails] = useState({
    comment: "",
    rating: 0,
  });

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Title>Reviews (6)</Title>
      <InnerContainer>
        <Grid container spacing={4}>
          {review.map((data, index, array) => {
            return (
              <Grid item xs={12} sm={12} md={12} key={index}>
                <ReveiwsCard data={data} index={index} array={array} />
              </Grid>
            );
          })}
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <TextContainer>
              <Text>Share your thoughts on “All Day Crossbody Bag”</Text>
              <Text
                sx={{
                  fontSize: "16px",
                  color: "#767676",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "small",
                  },
                }}
              >
                Your email address will not be published. Required fields are
                marked *
              </Text>
            </TextContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack justifyContent="center">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  sx={{
                    fontSize: "16px",
                    margin: `${theme.spacing(1)} 0px`,
                    color: "#767676",
                  }}
                >
                  Your rating *{" "}
                  <Rating
                    name="read-only"
                    value={reviewDetails.rating}
                    size="small"
                    onChange={(event) => {
                      setReviewDetails({
                        ...reviewDetails,
                        rating: event.target.value,
                      });
                    }}
                    sx={{
                      color: "#000",
                      height: "100%",
                    }}
                  />
                </Text>
              </Box>
              <OutlinedInput
                sx={{ borderRadius: "0px" }}
                placeholder="Your Review"
                multiline
                rows={4}
                value={reviewDetails.comment}
                onChange={(event) => {
                  setReviewDetails({
                    ...reviewDetails,
                    comment: event.target.value,
                  });
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </InnerContainer>
    </Container>
  );
}

export default ReviewsComponent;
