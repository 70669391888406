import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { login_context, register_context } from "../../utils/textUtils";
import RegisterSideBarComponent from "./card_widgets/RegisterSideBarComponent";
import UserNavlinksSidebar from "./UserNavlinksSidebar";
import CloseIcon from "../../images/icons/cross-icon-black.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  resetErrorState,
} from "../../features/authentication/authSlice";
import Cookies from "js-cookie";
import { red } from "@mui/material/colors";

const Container = styled(Box)(({ theme }) => ({
  width: "380px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(5),
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: "#E4E4E4",
  padding: `${theme.spacing(1)} 20px`,
  height: "55px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  position: "relative",
  textTransform: "uppercase",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
  },
}));

const CloseBtnContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "18px",
  right: "14px",
  [theme.breakpoints.down("sm")]: {
    right: "20px",
  },
}));

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(3),
  padding: "40px 20px",
  [theme.breakpoints.down("sm")]: {
    padding: "40px 20px",
  },
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const LinkBtn = styled(Link)(({ theme }) => ({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "24px",
  color: theme.palette.primary.main,
  transition: "0.4s",
  textAlign: "right",
  "&: hover": {
    color: "#c32929",
  },
}));

const LoginBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
}));

const Icon = styled("img")(({ theme }) => ({
  width: "15px",
  height: "15px",
}));

const AlertComponent = styled(Alert)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  fontWeight: "500",
  borderRadius: "0px",
  backgroundColor: red[100],
  "& .MuiAlert-icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiAlert-action": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function LoginBar({ open, setOpen }) {
  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.user);
  const [isUser, setIsUser] = useState(false);
  const [currentModel, setCurrentModel] = useState(true);
  const [userDetails, setUserDetails] = useState({
    username_email: "",
    password: "",
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const token = Cookies.get("access");

  const submitBtnHandler = async (event) => {
    event.preventDefault();
    try {
      await dispatch(loginUser(userDetails)).unwrap();
      const token = Cookies.get("access");
      if (token) {
        setIsUser(true);
        setUserDetails({
          username_email: "",
          password: "",
        });
      }
    } catch (err) {
      console.error("Failed to login: ", err);
    }
  };

  const handleClose = () => {
    setOpen();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const changeModel = () => {
    setCurrentModel((prevModel) => !prevModel);
  };

  useEffect(() => {}, [user, token, dispatch]);

  return (
    <SwipeableDrawer
      anchor={matches ? "bottom" : "right"}
      open={open}
      onClose={handleClose}
    >
      <Container>
        {token || isUser ? (
          <UserNavlinksSidebar
            handleClose={handleClose}
            setLoginModel={setIsUser}
          />
        ) : (
          <Box>
            <Header>
              <HeaderTitle>
                {currentModel ? login_context.title : register_context.btn_text}
              </HeaderTitle>
              <CloseBtnContainer>
                <IconButton onClick={handleClose}>
                  <Icon src={CloseIcon} alt="cross-icon" />
                </IconButton>
              </CloseBtnContainer>
            </Header>
            {currentModel ? (
              <>
                <Form component="form" onSubmit={submitBtnHandler}>
                  {error && (
                    <AlertComponent
                      onClose={() => {
                        dispatch(resetErrorState());
                      }}
                      severity="error"
                    >
                      {error}
                    </AlertComponent>
                  )}
                  <Input
                    label={login_context.input_text_one}
                    variant="outlined"
                    required
                    type="text"
                    name="username_email"
                    value={userDetails.username_email}
                    onChange={handleChange}
                  />
                  <Input
                    label={login_context.input_text_two}
                    variant="outlined"
                    required
                    type="password"
                    name="password"
                    value={userDetails.password}
                    onChange={handleChange}
                  />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label=" Remember me"
                      />
                    </FormGroup>
                    <LinkBtn to="/forgot-password" onClick={handleClose}>
                      {login_context.linkbtn_text_one}
                    </LinkBtn>
                  </Stack>
                  <LoginBtn type="submit">
                    {login_context.loginbtn_text}
                  </LoginBtn>
                  <Stack alignItems="center">
                    <Text>
                      {login_context.text}
                      <LinkBtn
                        onClick={() => {
                          setCurrentModel(false);
                        }}
                      >
                        {login_context.linkbtn_text_two}
                      </LinkBtn>
                    </Text>
                  </Stack>
                </Form>
              </>
            ) : (
              <RegisterSideBarComponent
                open={currentModel}
                setOpen={changeModel}
              />
            )}
          </Box>
        )}
      </Container>
    </SwipeableDrawer>
  );
}

export default LoginBar;
