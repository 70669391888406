import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import ImageOne from "../images/about_us_page_assets/about_us_banner.png";
import ImageTwo from "../images/about_us_page_assets/about-2.jpg";
import theme from "../utils/themes";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  padding: `${theme.spacing(5)} 0px`,
  boxSizing: "border-box",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "75%",
  margin: "auto",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const Body = styled(Box)(({ theme }) => ({
  width: "70%",
  margin: "auto",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2.5),
  padding: `${theme.spacing(5)} 0px`,
  boxSizing: "border-box",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: `${theme.spacing(3)} 0px`,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "26px",
  lineHeight: "32px",
  color: "#000",
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px",
    lineHeight: "30px",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  color: "#000",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    lineHeight: "20px",
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "30px",
  color: "#000",
}));

function AboutUsPage() {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      <InnerContainer>
        <ImageContainer>
          <Image src={ImageOne} alt="top-section-banner" />
        </ImageContainer>
        <Body>
          <Title>Our Story</Title>
          <Description>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </Description>
          <Description>
            Saw wherein fruitful good days image them, midst, waters upon, saw.
            Seas lights seasons. Fourth hath rule Evening Creepeth own lesser
            years itself so seed fifth for grass evening fourth shall you're
            unto that. Had. Female replenish for yielding so saw all one to
            yielding grass you'll air sea it, open waters subdue, hath. Brought
            second Made. Be. Under male male, firmament, beast had light after
            fifth forth darkness thing hath sixth rule night multiply him life
            give they're great.
          </Description>
          <Grid container spacing={matches ? 2 : 4}>
            <Grid item md={6}>
              <Stack>
                <Text>Our Mission</Text>
                <Description>
                  Quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat.
                </Description>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack>
                <Text>Our Vision</Text>
                <Description>
                  Quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat.
                </Description>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack>
                <Image src={ImageTwo} alt="banner" />
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack sx={{ height: "100%" }} justifyContent="center">
                <Text>The Company</Text>
                <Description>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                  sapien dignissim a elementum. Sociis metus, hendrerit mauris
                  id in. Quis sit sit ultrices tincidunt euismod luctus diam.
                  Turpis sodales orci etiam phasellus lacus id leo. Amet turpis
                  nunc, nulla massa est viverra interdum. Praesent auctor nulla
                  morbi non posuere mattis. Arcu eu id maecenas cras.
                </Description>
              </Stack>
            </Grid>
          </Grid>
        </Body>
      </InnerContainer>
    </Container>
  );
}

export default AboutUsPage;
