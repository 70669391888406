import { Alert, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { register_context } from "../../utils/textUtils";
import {
  resetErrorState,
  signUpUser,
} from "../../features/authentication/authSlice";
import Cookie from "js-cookie";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { red } from "@mui/material/colors";

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const LoginBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const PolicyText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "27px",
  color: "#767676",
}));

const AlertComponent = styled(Alert)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  fontWeight: "500",
  backgroundColor: red[100],
  borderRadius: "0px",
  "& .MuiAlert-icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiAlert-action": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function RegisterComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const submitBtnHandler = async (event) => {
    event.preventDefault();
    try {
      await dispatch(signUpUser(details)).unwrap();
      const token = Cookie.get("access");
      if (token) {
        navigate("/");
        setDetails({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        });
      }
    } catch (err) {
      console.log(error);
      console.error("Failed to login: ", err);
    }
  };

  return (
    <Form component="form" onSubmit={submitBtnHandler}>
      {error && (
        <AlertComponent
          onClose={() => {
            dispatch(resetErrorState());
          }}
          severity="error"
        >
          {error}
        </AlertComponent>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sx={12} md={12}>
          <Input
            name="first_name"
            value={details.first_name}
            onChange={handleChange}
            label={register_context.input_text_one}
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <Input
            name="last_name"
            value={details.last_name}
            onChange={handleChange}
            label={register_context.input_text_two}
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <Input
            name="email"
            value={details.email}
            onChange={handleChange}
            label={register_context.input_text_three}
            type="email"
            required
          />
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <Input
            name="password"
            value={details.password}
            onChange={handleChange}
            label={register_context.input_text_four}
            type="password"
            required
          />
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <PolicyText>{register_context.policy_text}</PolicyText>
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <LoginBtn type="submit">{register_context.btn_text}</LoginBtn>
        </Grid>
      </Grid>
    </Form>
  );
}

export default RegisterComponent;
