import {
  Alert,
  Backdrop,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { changepassword_context } from "../../utils/textUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewPassword,
  resetErrorState,
  setPasswordChangeSuccess,
  setPasswordError,
} from "../../features/authentication/authSlice";
import loader from "../../images/loader.gif";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  padding: "20px 4.6875rem",
  margin: "auto",
  minHeight: "calc(100vh - 55px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "40.625rem",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(6),
  padding: `${theme.spacing(8)} 0px`,
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const LoginBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  fontWeight: 400,
  lineHeight: "28px",
  color: theme.palette.primary.main,
  textAlign: "center",
}));

const LoadingLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
}));

const AlertComponent = styled(Alert)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  fontWeight: "500",
  backgroundColor: red[100],
  borderRadius: "0px",
  "& .MuiAlert-icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiAlert-action": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function AddNewPassword() {
  const { status, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordDetails, setPasswordDetails] = useState({
    newPassword: "",
    confirm_new_password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPasswordDetails({
      ...passwordDetails,
      [name]: value,
    });
  };

  const submitBtnHandler = async (event) => {
    event.preventDefault();
    try {
      dispatch(setPasswordError(null));
      if (
        passwordDetails.newPassword !== passwordDetails.confirm_new_password
      ) {
        dispatch(
          setPasswordError(
            "The New password and confirm new password does not match"
          )
        );
      } else {
        const response = await dispatch(
          addNewPassword(passwordDetails.newPassword)
        ).unwrap();
        if (response.status === 200) {
          dispatch(setPasswordChangeSuccess(true));
          navigate("/login");
          setTimeout(() => {
            dispatch(setPasswordChangeSuccess(false));
          }, 5000);
        }
      }
    } catch (error) {
      setPasswordDetails({
        newPassword: "",
        confirm_new_password: "",
      });
      console.log("Error while changing password: ", error);
    }
  };

  return (
    <>
      {status === "loading" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <LoadingLogo src={loader} alt="site-loader" />
        </Backdrop>
      )}
      <Container>
        <InnerContainer>
          <Title>Please Enter Your Password</Title>
          <Form component="form" onSubmit={submitBtnHandler}>
            {error && (
              <AlertComponent
                onClose={() => {
                  dispatch(resetErrorState());
                }}
                severity="error"
              >
                {error}
              </AlertComponent>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sx={12} md={12}>
                <Input
                  name="newPassword"
                  value={passwordDetails.newPassword}
                  onChange={handleChange}
                  label={changepassword_context.new_pass_text}
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12} sx={12} md={12}>
                <Input
                  name="confirm_new_password"
                  value={passwordDetails.confirm_new_password}
                  onChange={handleChange}
                  label={changepassword_context.confirm_new_pass_text}
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12} sx={12} md={12}>
                <LoginBtn type="submit">Set Password</LoginBtn>
              </Grid>
            </Grid>
          </Form>
        </InnerContainer>
      </Container>
    </>
  );
}

export default AddNewPassword;
