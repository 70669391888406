import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { navbar_context } from "../../utils/textUtils";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#000",
  padding: `${theme.spacing(1)} 0px`,
  height: "15px",
  position: "relative",
  zIndex: 1200,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#fff",
  lineHeight: "23px",
  fontWeight: 400,
}));

function TopBar() {
  return (
    <Container>
      <Title>{navbar_context.topbar_text}</Title>
    </Container>
  );
}

export default TopBar;
