import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate, useParams } from "react-router";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import CallSharpIcon from "@mui/icons-material/CallSharp";
import { TbBrandPaypalFilled } from "react-icons/tb";
import theme from "../utils/themes";
import { useSelector } from "react-redux";
import OrderProductsCard from "./card_widgets/OrderProductsCard";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "75%",
  margin: "auto",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: `${theme.spacing(5)} 0px`,
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const OrderDetailsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: "#e4e4e4",
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const HeaderTitle = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  color: "#000",
  fontSize: "16px",
  lineHeight: "28px",
}));

const InvoiceBtn = styled(Button)(({ theme }) => ({
  borderRadius: "0px",
  fontSize: "12px",
  lineHeight: "20px",
  fontWeight: "400",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  "&: hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const UserDetailsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  boxSizing: "border-box",
  border: "1px solid #00000020",
  borderBottom: "0px",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const PaymentDetailsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  boxSizing: "border-box",
  border: "1px solid #00000020",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  color: "#6c757d",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const TableCellComponent = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  padding: "0px",
  paddingLeft: "6px",
  [theme.breakpoints.down("md")]: {
    padding: "0px",
  },
}));

function OrderDetailsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { order_list } = useSelector((state) => state.orders);
  const selectedOrder = order_list.find((data) => data.id === Number(id));
  return (
    <Container>
      <InnerContainer>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={8}>
            <OrderDetailsContainer>
              <Stack alignItems="flex-start">
                <Button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  sx={{
                    borderRadius: "0px",
                    "&: hover": {
                      backgroundColor: "#fff",
                    },
                  }}
                  startIcon={<KeyboardBackspaceSharpIcon />}
                  disableRipple
                >
                  Back
                </Button>
              </Stack>
              <Header>
                <HeaderTitle>#{selectedOrder.order_number} </HeaderTitle>
                <InvoiceBtn startIcon={<FileDownloadOutlinedIcon />}>
                  Invoice
                </InvoiceBtn>
              </Header>
              <UserDetailsContainer>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6}>
                    <Stack>
                      <Text>Shipping Address</Text>
                      <SubText>
                        {selectedOrder.shipping_address.street} ,{" "}
                        {selectedOrder.shipping_address.street2}
                      </SubText>
                      <SubText>
                        {selectedOrder.shipping_address.city},{" "}
                        {selectedOrder.shipping_address.state.name}
                      </SubText>
                      <SubText>
                        {selectedOrder.shipping_address.country.name} -{" "}
                        {selectedOrder.shipping_address.zip}
                      </SubText>
                      <SubText>
                        <PersonSharpIcon sx={{ fontSize: "14px" }} />
                        {selectedOrder.shipping_address.contact_person}
                      </SubText>
                      <SubText>
                        <CallSharpIcon sx={{ fontSize: "14px" }} />
                        {selectedOrder.shipping_address.contact_phone}
                      </SubText>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Stack alignItems="flex-end">
                      <Text>Order Date</Text>
                      <SubText>{selectedOrder.order_date}</SubText>
                    </Stack>
                  </Grid>
                </Grid>
              </UserDetailsContainer>
              <Grid container>
                {selectedOrder?.items.map((data, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={12} md={12}>
                      <OrderProductsCard data={data} />
                    </Grid>
                  );
                })}
              </Grid>
              <PaymentDetailsContainer>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6}>
                    <Stack>
                      <Text>Payment Details</Text>
                      <SubText>
                        <TbBrandPaypalFilled /> {selectedOrder.payment_method}
                      </SubText>
                      <SubText>ID : {selectedOrder.payment_id}</SubText>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        alignItems: "flex-end",
                        marginLeft: "auto",
                        [theme.breakpoints.down("md")]: {
                          margin: "0px",
                        },
                      }}
                    >
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCellComponent>
                                <Text sx={{ margin: "0px", color: "#6c757d" }}>
                                  Total :
                                </Text>
                              </TableCellComponent>
                              <TableCellComponent>
                                <Text
                                  sx={{ margin: "0px", color: "#6c757d" }}
                                  textAlign="right"
                                >
                                  &nbsp; ${selectedOrder.sub_total}
                                </Text>
                              </TableCellComponent>
                            </TableRow>
                            <TableRow>
                              <TableCellComponent>
                                <Text sx={{ margin: "0px", color: "#6c757d" }}>
                                  Discount :
                                </Text>
                              </TableCellComponent>
                              <TableCellComponent>
                                <Text
                                  sx={{ margin: "0px", color: "#6c757d" }}
                                  textAlign="right"
                                >
                                  &nbsp; ${selectedOrder.discount}
                                </Text>
                              </TableCellComponent>
                            </TableRow>
                            <TableRow>
                              <TableCellComponent>
                                <Text sx={{ margin: "0px" }}>
                                  Grand Total :
                                </Text>
                              </TableCellComponent>
                              <TableCellComponent>
                                <Text
                                  sx={{ margin: "0px", fontWeight: 700 }}
                                  textAlign="right"
                                >
                                  &nbsp; ${selectedOrder.total_amount}
                                </Text>
                              </TableCellComponent>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </PaymentDetailsContainer>
            </OrderDetailsContainer>
          </Grid>
        </Grid>
      </InnerContainer>
    </Container>
  );
}

export default OrderDetailsPage;
