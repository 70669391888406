import {
  Alert,
  Backdrop,
  Box,
  Button,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import AddressFormComponent from "../components/address_page_components/AddressFormComponent";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import AddressCard from "../components/address_page_components/card_widgets/AddressCard";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAddress,
  fetchCountries,
  fetchUserAddress,
} from "../features/address/addressSlice";
import loader from "../images/loader.gif";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  padding: `${theme.spacing(5)} 0px`,
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1),
  width: "70%",
  margin: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "22px",
  lineHeight: "22px",
  color: "#000",
  marginBottom: theme.spacing(2),
}));

const AddAddresBtn = styled(Button)(({ theme }) => ({
  border: "1px dashed #000",
  padding: "60px",
  textAlign: "center",
  background: "rgba(207, 207, 207, 0.267)",
  width: "100%",
  fontSize: "18px",
  "&: hover": {
    backgroundColor: "rgba(71, 71, 71, 0.226)",
    cursor: "pointer",
  },
}));

const BtnContainer = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0px`,
}));

const LoadingLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
}));

function AddressPage() {
  const dispatch = useDispatch();
  const { response_text, address, status } = useSelector(
    (state) => state.address
  );
  const [openForm, setOpenForm] = useState(false);
  const [open, setOpen] = useState(false);

  const deleteBtnHandler = async (id) => {
    try {
      const response = await dispatch(deleteAddress(id)).unwrap();
      console.log(response);
      if (response.message) {
        console.log("deleter btn click");
        setOpen(true);
        dispatch(fetchUserAddress()).unwrap();
      }
    } catch (error) {
      console.log("Error while deleting the address:", error);
    }
  };

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchUserAddress());
  }, [dispatch]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        sx={{
          "&.MuiSnackbar-root": {
            top: "100px",
          },
        }}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {response_text}
        </Alert>
      </Snackbar>
      {status === "loading" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <LoadingLogo src={loader} alt="site-loader" />
        </Backdrop>
      )}
      <Container>
        <InnerContainer>
          <Title>ADD ADDRESS</Title>
          {openForm ? (
            <AddressFormComponent
              setOpenSuccessDialog={setOpen}
              setOpen={setOpenForm}
            />
          ) : (
            <Grid container spacing={4}>
              {address.length === 0 ? (
                <Grid item xs={12} sm={12} md={12}>
                  <Title
                    sx={{
                      width: "100%",
                      minHeight: "25vh",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Address Added ...
                  </Title>
                </Grid>
              ) : (
                <>
                  {address?.map((data, index) => (
                    <Grid item key={index} xs={12} sm={6} md={6}>
                      <AddressCard
                        index={index}
                        data={data}
                        dltAddress={deleteBtnHandler}
                      />
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          )}
          {!openForm && (
            <Grid container>
              <Grid item xs={12} md={6}>
                <BtnContainer>
                  <AddAddresBtn
                    startIcon={<AddSharpIcon />}
                    onClick={() => {
                      setOpenForm(true);
                    }}
                  >
                    Add Address
                  </AddAddresBtn>
                </BtnContainer>
              </Grid>
            </Grid>
          )}
        </InnerContainer>
      </Container>
    </>
  );
}

export default AddressPage;
