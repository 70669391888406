import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import qs from 'qs'; // Import qs for query string serialization

const link = process.env.REACT_APP_BACKEND_API;

export const fetchProducts = createAsyncThunk("products/fetchProducts", async ({ currentPage, price, size, color, sort }, thunkAPI) => {
    const params = {
        country: "us",
        page: currentPage    // Default to page 1 if pagination is required
    };

    // Conditionally add parameters
    if (size) params.size = size;
    if (sort) params.sort = sort;
    if (color && color.length) params.color = color;
    if (price) params.minPrice = price[0];
    if (price) params.maxPrice = price[1];

    try {
        const response = await axios.get(`${link}/in/shop/api/`, {
            params,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: false
        })
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error.response.data);
    }
})

const initialState = {
    status: "idle",
    products: [],
    filterOptions: {
        sizes: [],
        colors: [],
        min_price: "",
        max_price: "",
    },
    total: 0,
    pages: 0,
    error: null
}

export const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state) => {
            state.status = "loading"
        }).addCase(fetchProducts.fulfilled, (state, action) => {
            state.status = "success";
            state.products = action.payload.results.products;
            state.filterOptions.sizes = action.payload.results.sizes;
            state.filterOptions.colors = action.payload.results.colors;
            state.filterOptions.min_price = Number(action.payload.results.min_price);
            state.filterOptions.max_price = Number(action.payload.results.max_price);
            state.total = Number(action.payload.results.total);
            state.pages = Number(action.payload.results.page_count);
        }).addCase(fetchProducts.rejected, (state, action) => {
            state.status = "rejected";
            state.error = action.error.message
        })
    }
})