import { Box, Typography, Grid, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import PromoImg from "../../images/shop-slider-img.png";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "250px",
  backgroundColor: "#fff",
  position: "absolute",
  zIndex: 4,
  padding: "70px 0px",
  boxShadow: "0 10px 25px 0 rgba(34, 34, 34, 0.05)",
  visibility: "visible",
  boxSizing: "border-box",
  opacity: 1,
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  margin: "auto",
  minHeight: "250px",
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(4),
}));

const LinkTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "27px",
  color: "#000",
  transition: "0.3s",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  "&: hover": {
    color: "#c32929",
  },
}));

const LinkText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#000",
  transition: "color 0.3s",
  cursor: "pointer",
  marginBottom: theme.spacing(1),
  position: "relative",
  width: "fit-content",
  "&:hover": {
    "&::after": {
      width: "30px",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "0",
    height: "2px",
    backgroundColor: "#000",
    transition: "width 0.3s ease-out",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
}));

const MenuImageHolder = styled(Box)(({ theme }) => ({
  maxWidth: "40vh",
  height: "50vh",
  overflow: "hidden",
}));

const Image = styled("img")(({ theme }) => ({
  objectFit: "cover",
  maxWidth: "100%",
}));

const TextContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  bottom: "30px",
  left: "20px",
  rowGap: theme.spacing(0.5),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "500",
  lineHeight: "24px",
  color: "#fff",
}));

// const Text = styled(Typography)(({ theme }) => ({
//   fontSize: "16px",
//   fontWeight: "500",
//   lineHeight: "27px",
//   position: "relative",
//   "&::after": {
//     content: `''`,
//     display: "block",
//     position: "absolute",
//     top: "100%",
//     left: 0,
//     width: 0,
//     maxWidth: "100%",
//     height: "2px",
//     transition: "width 0.28s cubic-bezier(0.47, 0, 0.745, 0.715)",
//     backgroundColor: "#fff",
//   },
// }));
const Text = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "27px",
  position: "relative",
  color: "#fff",
  cursor: "pointer",
  "&::after": {
    content: '""', // Ensure content is not empty
    position: "absolute",
    bottom: "-2px", // Adjust this to position the underline correctly
    left: 0,
    width: "25%",
    height: "2px",
    backgroundColor: "#fff",
    transition: "width 0.28s cubic-bezier(0.47, 0, 0.745, 0.715)",
  },
}));

function ShopSlider({ open, containerRef, setOpen }) {
  return (
    <Fade
      in={open}
      container={containerRef.current}
      timeout={{ enter: 500, exit: 0 }}
    >
      <Container
        onMouseLeave={() => {
          setOpen();
        }}
      >
        <InnerContainer>
          <Grid container>
            <Grid item md={3}>
              <LinkTitle>Shorts</LinkTitle>
              <LinkText>Active Wear</LinkText>
            </Grid>
            <Grid item md={3}>
              <LinkTitle>LEGGINGS</LinkTitle>
              <LinkText>Leggings</LinkText>
              <LinkText>Free Size</LinkText>
            </Grid>
            <Grid item md={3}>
              <LinkTitle>BRA</LinkTitle>
              <LinkText>Running</LinkText>
              <LinkText>Gym</LinkText>
              <LinkText>Wear</LinkText>
              <LinkText>Hiking</LinkText>
              <LinkText>Sports Bra</LinkText>
            </Grid>
            <Grid item md={3}>
              <ImageContainer>
                <MenuImageHolder>
                  <Image src={PromoImg} alt="promo-img" />
                </MenuImageHolder>
                <TextContainer>
                  <Title>Stylish Summer Shorts</Title>
                  <Text>SHOP NOW</Text>
                </TextContainer>
              </ImageContainer>
            </Grid>
          </Grid>
        </InnerContainer>
      </Container>
    </Fade>
  );
}

export default ShopSlider;
