/* eslint-disable react-hooks/exhaustive-deps */
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "./App.css";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import theme from "./utils/themes";
import { useEffect, useRef, useState } from "react";
import LoginBar from "./components/sidebars/LoginBar";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import ProductsDetailsPage from "./pages/ProductsDetailsPage";
import MyProfilePage from "./pages/MyProfilePage";
import LoginPage from "./pages/LoginPage";
import OtpComponent from "./components/login_page_components/OtpComponent";
import AddressPage from "./pages/AddressPage";
import ContactUsPage from "./pages/ContactUsPage";
import SalesPage from "./pages/SalesPage";
import ForgotPasswordComponent from "./components/login_page_components/ForgotPasswordComponent";
import CartPage from "./pages/CartPage";
import ChangePassword from "./components/login_page_components/ChangePassword";
import TrackOrderPage from "./pages/TrackOrderPage";
import OrdersPageComponent from "./pages/OrdersPageComponent";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import FaqPage from "./pages/FaqPage";
import ShoppingBagIcon from "./images/icons/shopping-bag-icon-black.svg";
import AccountIcon from "./images/icons/account-icon-black.svg";
import HomeContainedIcon from "./images/icons/home-contained-icon.svg";
import HomeOutlinedIcon from "./images/icons/home-outlined-icon.svg";
import ShoppingBagContianedIcon from "./images/icons/shopping-bag-contained.svg";
import AccountContianedIcon from "./images/icons/account-contained-icon.svg";
import RequireAuth from "./auth/RequireAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDetails,
  resetErrorState,
} from "./features/authentication/authSlice";
import Cookies from "js-cookie";
import AddNewPassword from "./components/login_page_components/AddNewPassword";
import RequireOtpToken from "./auth/RequireOtpToken";
import RequirePasswordToken from "./auth/RequirePasswordToken";
import ShopPageV1 from "./pages/ShopPageV1";
import HomePage from "./pages/HomePage";
import OtherPageNavbar from "./components/navbar/OtherPageNavbar";
import AboutUsPage from "./pages/AboutUsPage";

const Icon = styled("img")(({ theme }) => ({
  width: "20px",
  height: "20px",
}));

function App() {
  const [open, setOpen] = useState();
  const navigate = useNavigate();
  const { user, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let token = Cookies.get("access");
  const userDetailsFetched = useRef(false);

  const mathches = useMediaQuery(theme.breakpoints.down("md"));

  const handlerOpen = () => {
    setOpen(true);
  };

  const handlerClose = () => {
    setOpen(false);
  };

  const isHomePage = pathname === "/";
  const isShopPage = pathname === "/shop";
  const isAccountPage = pathname === "/users/myprofile";

  useEffect(() => {
    if (!userDetailsFetched.current) {
      if (token) {
        dispatch(fetchUserDetails());
        userDetailsFetched.current = true;
      }
    }
    if (error) {
      setTimeout(() => {
        dispatch(resetErrorState());
      }, 6000);
    }
  }, [token, user, error, pathname]);

  useEffect(() => {
    dispatch(resetErrorState());
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);

  return (
    <div className="App">
      {
        pathname === "/" ?
          <Navbar /> : <OtherPageNavbar />
      }
      <Routes>
        <Route
          path="/"
          element={<HomePage />}
        />
        <Route path="/shop/:slug" element={<ProductsDetailsPage />} />
        {/* <Route path="/shop" element={<ShopPage />} /> */}
        <Route path="/shop" element={<ShopPageV1 />} />
        <Route
          path="/users/myprofile"
          element={
            <RequireAuth>
              <MyProfilePage />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/otp-validate"
          element={
            <RequireOtpToken>
              <OtpComponent />
            </RequireOtpToken>
          }
        />
        <Route
          path="/address"
          element={
            <RequireAuth>
              <AddressPage />
            </RequireAuth>
          }
        />
        <Route path="/contact_us" element={<ContactUsPage />} />
        <Route path="/sales" element={<SalesPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordComponent />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route
          path="/change-password"
          element={
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          }
        />
        <Route
          path="/add-newpassword"
          element={
            <RequirePasswordToken>
              <AddNewPassword />
            </RequirePasswordToken>
          }
        />
        <Route path="/track-order" element={<TrackOrderPage />} />
        <Route
          path="/orders"
          element={
            <RequireAuth>
              <OrdersPageComponent />
            </RequireAuth>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <RequireAuth>
              <OrderDetailsPage />
            </RequireAuth>
          }
        />
        <Route path="/faqs" element={<FaqPage />} />
      </Routes>
      <Footer />
      {mathches && (
        <Paper
          sx={{ position: "sticky", bottom: 0, left: 0, right: 0, zIndex: 5 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={""}
            onChange={(event, newValue) => { }}
          >
            <BottomNavigationAction
              onClick={() => [navigate("/")]}
              label="Home"
              icon={
                isHomePage ? (
                  <Icon src={HomeContainedIcon} alt="home-contained-icon" />
                ) : (
                  <Icon src={HomeOutlinedIcon} alt="home-outlined-icon" />
                )
              }
            />
            <BottomNavigationAction
              onClick={() => {
                navigate("/shop");
              }}
              label="Shop"
              icon={
                isShopPage ? (
                  <Icon
                    src={ShoppingBagContianedIcon}
                    alt="home-contained-icon"
                  />
                ) : (
                  <Icon src={ShoppingBagIcon} alt="home-outlined-icon" />
                )
              }
            />
            <BottomNavigationAction
              label="Profile"
              icon={
                isAccountPage ? (
                  <Icon src={AccountContianedIcon} alt="account-icon" />
                ) : (
                  <Icon src={AccountIcon} alt="account-icon" />
                )
              }
              onClick={handlerOpen}
            />
          </BottomNavigation>
        </Paper>
      )}
      <LoginBar open={open} setOpen={handlerClose} />
    </div>
  );
}

export default App;
