import { Box, Card, Typography, styled } from "@mui/material";
import React from "react";

const Container = styled(Card)(({ theme }) => ({
  borderRadius: "0px",
  boxShadow: "none",
  cursor: "pointer",
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  marginBottom: theme.spacing(1),
}));

const ImgEle = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  color: theme.palette.primary.light,
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000",
  lineHeight: "24px",
  display: "flex",
  gap: "4px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "20px",
  },
}));

const OldPriceSpan = styled("span")(({ theme }) => ({
  textDecoration: "line-through",
}));

const PriceSpan = styled("span")(({ theme }) => ({}));

const PercentSpan = styled("span")(({ theme }) => ({
  color: "#d93e3d",
}));

function ProductsCard({ data }) {
  return (
    <Container>
      <ImgContainer>
        <ImgEle src={data.img} alt="product-img" />
      </ImgContainer>
      <Text>{data.title}</Text>
      <PriceText>
        {data.old_price && <OldPriceSpan>${data.old_price} </OldPriceSpan>}
        <PriceSpan>${data.sale_price}</PriceSpan>
        {data.off_percent && <PercentSpan>{data.off_percent}% off</PercentSpan>}
      </PriceText>
    </Container>
  );
}

export default ProductsCard;
