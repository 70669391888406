import { Skeleton, Stack } from "@mui/material";
import React from "react";
import theme from "../../../utils/themes";

function BlankCard() {
  return (
    <Stack spacing={1}>
      <Skeleton
        sx={{
          height: 622,
          width: "100%",
          "&.MuiSkeleton-root": {
            "&::after": {
              animationDuration: "0.8s",
            },
          },
          [theme.breakpoints.down("md")]: {
            height: 500,
          },
          [theme.breakpoints.down("sm")]: {
            height: 300,
          },
        }}
        animation="wave"
        variant="rectangular"
      />
      <Skeleton
        sx={{
          height: 20,
          width: "35%",
          "&.MuiSkeleton-root": {
            "&::after": {
              animationDuration: "0.8s",
            },
          },
        }}
        animation="wave"
        variant="rectangular"
      />
      <Skeleton
        sx={{
          height: 15,
          width: "15%",
          "&.MuiSkeleton-root": {
            "&::after": {
              animationDuration: "0.8s",
            },
          },
        }}
        animation="wave"
        variant="rectangular"
      />
    </Stack>
  );
}

export default BlankCard;
