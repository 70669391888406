import { Card, Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Container = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
  padding: `${theme.spacing(2)}`,
  borderBottom: "1px solid #0000007d",
  borderRadius: "0px",
  boxShadow: "none",
  height: "100%",
  boxSizing: "border-box",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Body = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "20px",
  color: "#000",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#000",
}));

const DeleteBtn = styled(Button)(({ theme }) => ({
  width: "fit-content",
  font: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  borderBottom: "2px solid #000",
  borderRadius: "none",
  padding: "none",
  "&: hover": {
    backgroundColor: "#fff",
  },
  "&.MuiButton-root": {
    padding: "0px",
    borderRadius: "0px",
  },
}));

function AddressCard({ data, index, dltAddress }) {
  return (
    <Container>
      <Header>
        <Title>Address {index + 1}</Title>
        <DeleteBtn
          onClick={() => {
            dltAddress(data.id);
          }}
        >
          Delete
        </DeleteBtn>
      </Header>
      <Body>
        <Text>{data?.contact_person}</Text>
        <Text>
          {data?.street}, {data?.street2}
        </Text>
        <Text>
          {data?.city} - {data?.zip}
        </Text>
        <Text>{data?.state_name}</Text>
        <Text>{data?.country_name}</Text>
      </Body>
      <Footer>
        <Text>+ {data?.contact_phone}</Text>
      </Footer>
    </Container>
  );
}

export default AddressCard;
