import {
  Box,
  Step,
  StepButton,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import ShopingBagComponent from "../components/cart_page_components/ShopingBagComponent";
import ShippingAndCheckoutComponent from "../components/cart_page_components/ShippingAndCheckoutComponent";
import ConfirmOrderComponent from "../components/cart_page_components/ConfirmOrderComponent";
import theme from "../utils/themes";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 55px)",
  padding: `${theme.spacing(5)} 0px`,
  paddingTop: "0px",
  backgroundColor: "#fff",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "75%",
  margin: "auto",
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
}));

// const Title = styled(Typography)(({ theme }) => ({
//   fontSize: "30px",
//   fontWeight: 500,
//   lineHeight: "28px",
//   color: theme.palette.primary.main,
//   [theme.breakpoints.down("md")]: {
//     fontSize: "26px",
//     lineHeight: "24px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "24px",
//   },
// }));

const StepperContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: `${theme.spacing(10)} 0px`,
  [theme.breakpoints.down("sm")]: {
    padding: `${theme.spacing(5)} 0px`,
  },
}));

const StepLabel = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1),
}));

const StepperTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "18px",
  fontHeight: "32px",
  color: theme.palette.primary.main,
  textTransform: "uppercase",
  [theme.breakpoints.down("sm")]: {
    fontSize: "small",
  },
}));

const StepperText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  fontHeight: "24px",
  color: "#767676",
  textTransform: "capitalize",
  [theme.breakpoints.down("sm")]: {
    fontSize: "small",
  },
}));

const renderComponent = (step, next) => {
  switch (step) {
    case 0:
      return <ShopingBagComponent next={next} />;
    case 1:
      return <ShippingAndCheckoutComponent next={next} />;
    case 2:
      return <ConfirmOrderComponent />;
    default:
      return <div>Not Found</div>;
  }
};

const steps = [
  {
    title: "SHOPPING BAG",
    text: "Manage Your Items List",
    mobile_text: "CART",
  },
  {
    title: "SHIPPING AND CHECKOUT",
    text: "Checkout Your Items List",
    mobile_text: "CHECKOUT",
  },
  {
    title: "CONFIRMATION",
    text: "Submited Your Order",
    mobile_text: "CONFIRMATION",
  },
];

function CartPage() {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);

  function scrollToDiv() {
    document
      .getElementById("stepper")
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    scrollToDiv();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Container>
      <InnerContainer>
        <StepperContainer id="stepper">
          <Stepper
            nonLinear
            activeStep={activeStep}
            // alternativeLabel={matches ? false : true}
            alternativeLabel
            // orientation={matches ? "vertical" : "horizontal"}
          >
            {steps.map((label, index) => {
              const labelProps = {};
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
              return (
                <Step key={index}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    <StepLabel {...labelProps}>
                      {matches ? (
                        <StepperTitle>{label.mobile_text}</StepperTitle>
                      ) : (
                        <>
                          <StepperTitle>{label.title}</StepperTitle>
                          <StepperText>{label.text}</StepperText>
                        </>
                      )}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </StepperContainer>
        {renderComponent(activeStep, handleNext)}
      </InnerContainer>
    </Container>
  );
}

export default CartPage;
