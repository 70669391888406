/* eslint-disable no-dupe-keys */
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { navbar_context } from "../../utils/textUtils";
import { NavLink, useNavigate } from "react-router-dom";
import AccountIconBlack from "../../images/icons/account-icon-black.svg";
import MenuIconBlack from "../../images/icons/menu-icon-black.svg";
import CartIconBlack from "../../images/icons/cart-icon-black.svg";
import SearchIconBlack from "../../images/icons/search-icon-black.svg";
import CloseIconBlack from "../../images/icons/cross-icon-black.svg";
import theme from "../../utils/themes";
import LoginBar from "../sidebars/LoginBar";
import CartSideBar from "../sidebars/CartSideBar";
import SearchTopBar from "../sidebars/SearchTopBar";
import MobileNavigationSlider from "../sidebars/MobileNavigationSlider";
import Cookies from "js-cookie";
import TopBar from "./TopBar";
import ShopSlider from "../sidebars/ShopSlider";

const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  backgroundColor: "white",
  transition: "background-color 0.3s",
  position: "sticky",
  top: "0px",
  left: "0px",
  right: "0px",
  zIndex: 9,
}));

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  backgroundColor: "inherit", // Use the same background as MainContainer
  position: "sticky",
  top: "0px",
  left: "0px",
  right: "0px",
  zIndex: 99999,
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  height: "55px",
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing(1)} 0px`,
}));

const LinkComponent = styled(NavLink)(({ theme, scrollPosition }) => ({
  fontSize: "14px",
  fontWeight: 400,
  color: theme.palette.primary.main,
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  textTransform: "uppercase",
}));

const ItemsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  gap: theme.spacing(5),
  height: "100%",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  cursor: "pointer",
}));

const Logo = styled("img")(({ theme }) => ({
  width: "25%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const IconsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  height: "100%",
  gap: theme.spacing(4),
}));

const Icon = styled("img")(({ theme }) => ({
  width: "20px",
  height: "20px",
}));

function OtherPageNavbar() {
  const token = Cookies.get("access");
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [input, setInput] = useState("");

  const [openDrawers, setOpenDrawers] = useState({
    openLoginBar: false,
    openCartBar: false,
    openSearchBar: false,
    openShopBar: false,
    openMenBar: false,
    openMobileNavigation: false,
  });

  const containerRef = useRef(null);

  const handleOpenSearchBar = () => {
    setOpenDrawers({
      openSearchBar: true,
      openLoginBar: false,
      openCartBar: false,
      openShopBar: false,
      openMenBar: false,
    });
  };

  const handleClose = () => {
    setInput("");
    setOpenDrawers({
      openLoginBar: false,
      openCartBar: false,
      openSearchBar: false,
      openShopBar: false,
      openMenBar: false,
      openMobileNavigation: false,
    });
  };

  useEffect(() => {
    handleClose();
  }, [matches]);

  return (
    <>
      <MainContainer ref={containerRef}>
        <TopBar />
        <Container>
          <InnerContainer>
            <Grid container>
              <Grid xs={4} sm={4} md={4} item>
                {!matches ? (
                  <ItemsContainer>
                    {navbar_context.menu_links.map((menu, index) => {
                      if (menu.menu === "Login" && token) {
                        return null;
                      } else {
                        return (
                          <LinkComponent
                            key={index}
                            to={menu.path}
                            onMouseEnter={() => {
                              if (menu.menu === "Shop") {
                                setOpenDrawers({
                                  openShopBar: true,
                                  openMenBar: false,
                                  openLoginBar: false,
                                  openCartBar: false,
                                  openSearchBar: false,
                                });
                              }
                            }}
                          >
                            {menu.menu}
                          </LinkComponent>
                        );
                      }
                    })}
                  </ItemsContainer>
                ) : (
                  <>
                    {openDrawers.openMobileNavigation ? (
                      <IconButton onClick={handleClose}>
                        <Icon src={CloseIconBlack} alt="cross-icon" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setOpenDrawers({
                            openMobileNavigation: true,
                            openLoginBar: false,
                            openCartBar: false,
                            openSearchBar: false,
                            openShopBar: false,
                            openMenBar: false,
                          });
                        }}
                      >
                        <Icon src={MenuIconBlack} alt="menu-icon" />
                      </IconButton>
                    )}
                  </>
                )}
              </Grid>
              <Grid xs={4} sm={4} md={4} item>
                <LogoContainer
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <Logo src={navbar_context.site_logo_black} alt="site-logo" />
                </LogoContainer>
              </Grid>
              <Grid xs={4} sm={4} md={4} item>
                {!matches ? (
                  <IconsContainer>
                    {openDrawers.openSearchBar ? (
                      <IconButton onClick={handleClose}>
                        <Icon src={CloseIconBlack} alt="cross-icon" />
                      </IconButton>
                    ) : (
                      <IconButton onClick={handleOpenSearchBar}>
                        <Icon src={SearchIconBlack} alt="search-icon" />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => {
                        setOpenDrawers({
                          openLoginBar: true,
                          openSearchBar: false,
                          openCartBar: false,
                          openShopBar: false,
                          openMenBar: false,
                        });
                      }}
                    >
                      <Icon src={AccountIconBlack} alt="account-icon" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenDrawers({
                          openCartBar: true,
                          openSearchBar: false,
                          openLoginBar: false,
                          openShopBar: false,
                          openMenBar: false,
                        });
                      }}
                    >
                      <Icon src={CartIconBlack} alt="shopping-bag-icon" />
                    </IconButton>
                  </IconsContainer>
                ) : (
                  <IconsContainer>
                    <IconButton
                      onClick={() => {
                        setOpenDrawers({
                          openCartBar: true,
                          openSearchBar: false,
                          openLoginBar: false,
                          openShopBar: false,
                          openMenBar: false,
                        });
                      }}
                    >
                      <Icon src={CartIconBlack} alt="shopping-bag-icon" />
                    </IconButton>
                  </IconsContainer>
                )}
              </Grid>
            </Grid>
          </InnerContainer>
        </Container>
        <ShopSlider
          containerRef={containerRef}
          open={openDrawers.openShopBar}
          setOpen={handleClose}
        />
        <MobileNavigationSlider
          containerRef={containerRef}
          open={openDrawers.openMobileNavigation}
          setOpen={handleClose}
          input={input}
          setInput={setInput}
        />
        <SearchTopBar
          containerRef={containerRef}
          open={openDrawers.openSearchBar}
          setOpen={setOpenDrawers}
          input={input}
          setInput={setInput}
        />
      </MainContainer>
      <LoginBar open={openDrawers.openLoginBar} setOpen={handleClose} />
      <CartSideBar open={openDrawers.openCartBar} setOpen={handleClose} />
    </>
  );
}

export default OtherPageNavbar;
