import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useSelector } from "react-redux";

const Container = styled(Box)(({ theme }) => ({
  margin: `0px auto`,
  padding: `${theme.spacing(3)} 0px`,
  width: "90%",
  height: "100% ",
  boxSizing: "border-box",
}));

const SizeBtn = styled(Button)(({ theme }) => ({
  borderRadius: "0px",
  borderColor: "#cccccc",
  transition: "none",
  padding: "12px 6px",
  width: "100%",
  height: "100%",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

const AccordionContianer = styled(Box)(({ theme }) => ({}));

const AccordionComponent = styled(Accordion)(({ theme }) => ({
  borderTop: "1px solid #cccccc",
  borderRadius: "0px",
  boxShadow: "none",
  //   =====================

  color: "#696969",
  margin: 0,
  width: "100%",
  textAlign: "left",
  background: "transparent",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.2px",
  transition: "none",
  //   =====================
  ":first-of-type": {
    borderRadius: "0px",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#696969",
  height: "100%",
  display: "flex",
  alignItems: "center",
}));

const AccordionSummeryComponent = styled(AccordionSummary)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.dark,
  padding: "10px 0px",
  [theme.breakpoints.down("md")]: {
    padding: "10px 16px",
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const InputContainers = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "97%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    height: "96%",
  },
}));

const BtnContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: `0px auto`,
  padding: `${theme.spacing(4)} ${theme.spacing(0)}`,
  backgroundColor: "#fff",
  borderTop: "1px solid #cccccc",
  boxSizing: "border-box",
  "& .MuiButton-root": {
    borderRadius: "0px",
  },
}));

const MainColorInput = styled(IconButton)(({ theme, isSelected }) => ({
  padding: "4px",
  border: isSelected && "1px solid #000",
}));

const CustomRadioInput = styled(Box)(({ theme, bgColor }) => ({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  backgroundColor: bgColor,
}));

const marks = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 10000,
    label: "$10000",
  },
];

function FilterSelection({ filters, setFilters, setOpen }) {
  const { filterOptions } = useSelector((state) => state.products);
  const [expanded, setExpanded] = useState("panel1");

  const priceChangeHandler = (event, newValue) => {
    setFilters({
      ...filters,
      price: newValue,
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const removeHashChar = (color) => color.substring(1);

  return (
    <Container>
      <TopContainer>
        <Text>Filter</Text>
        <CloseSharpIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setOpen(false);
          }}
        />
      </TopContainer>
      <Divider />
      <InputContainers>
        <AccordionContianer>
          {/* <AccordionComponent
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                >
                <AccordionSummeryComponent
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                Category
                </AccordionSummeryComponent>
                <AccordionDetails>
                <FormGroup>
                {categoryInputs.map((category, index) => (
                      <FormControlLabel
                      key={index}
                      control={<Checkbox />}
                      label={category}
                      />
                      ))}
                      </FormGroup>
                      </AccordionDetails>
                      </AccordionComponent> */}
          <AccordionComponent
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummeryComponent
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Price
            </AccordionSummeryComponent>
            <AccordionDetails>
              <FormGroup>
                <Slider
                  min={0}
                  max={10000}
                  marks={marks}
                  getAriaLabel={() => "Price range"}
                  value={filters.price}
                  onChange={priceChangeHandler}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                />
              </FormGroup>
            </AccordionDetails>
          </AccordionComponent>
          <AccordionComponent
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummeryComponent
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Color
            </AccordionSummeryComponent>
            <AccordionDetails>
              <Grid container spacing={1}>
                {filterOptions.colors.map((productColor, index) => {
                  return (
                    <Grid item md={2} key={index}>
                      <MainColorInput
                        isSelected={filters.color.includes(
                          removeHashChar(productColor)
                        )}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            color: filters.color.includes(
                              removeHashChar(productColor)
                            )
                              ? filters.color.filter(
                                  (color) =>
                                    color !== removeHashChar(productColor)
                                )
                              : [
                                  ...filters.color,
                                  removeHashChar(productColor),
                                ],
                          });
                        }}
                      >
                        <CustomRadioInput bgColor={productColor} />
                      </MainColorInput>
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </AccordionComponent>
          <AccordionComponent
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummeryComponent
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Size
            </AccordionSummeryComponent>
            <AccordionDetails>
              <Grid container spacing={1} columns={{ md: 0 }}>
                {filterOptions.sizes.map((size, index) => (
                  <Grid item xs={2} sm={2} md={2} key={index}>
                    <SizeBtn
                      variant={size === filters.size ? "contained" : "outlined"}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          size: size,
                        });
                      }}
                    >
                      {size}
                    </SizeBtn>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </AccordionComponent>
        </AccordionContianer>
        <BtnContainer>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onClick={() => {
                    setFilters({
                      currentPage: 1,
                      price: [50, 9000],
                      size: "",
                      color: [],
                    });
                    setOpen(false);
                  }}
                >
                  Clear All
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  View {filterOptions.total} Items
                </Button>
              </Grid>
            </Grid>
          </Box>
        </BtnContainer>
      </InputContainers>
    </Container>
  );
}

export default FilterSelection;
