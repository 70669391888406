import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  fetchUserAddress,
} from "../../features/address/addressSlice";

const Form = styled(Box)(({ theme }) => ({}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const Btn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

function AddressFormComponent({ setOpen, setOpenSuccessDialog }) {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.address);
  const [statesList, setStatesList] = useState([]);
  const [addressDetails, setAddressDetails] = useState({
    address_one: "",
    address_two: "",
    country: null,
    state: "",
    city: "",
    zip_code: "",
    contact_person: "",
    contact_phone: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddressDetails({
      ...addressDetails,
      [name]: value,
    });
  };

  const submitBtnHandler = async (event) => {
    event.preventDefault();
    const data = {
      street: addressDetails.address_one,
      street2: addressDetails.address_two,
      city: addressDetails.city,
      state: addressDetails.state,
      country: addressDetails.country.name,
      zip: addressDetails.zip_code,
      contact_person: addressDetails.contact_person,
      contact_phone: addressDetails.contact_phone,
    };
    try {
      const response = await dispatch(addAddress(data)).unwrap();
      if (response.status) {
        setOpenSuccessDialog(true);
        setAddressDetails({
          address_one: "",
          address_two: "",
          country: null,
          state: "",
          city: "",
          zip_code: "",
          contact_person: "",
          contact_phone: "",
        });
        dispatch(fetchUserAddress()).unwrap();
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form component="form" onSubmit={submitBtnHandler}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            required
            variant="outlined"
            label="Address 1"
            name="address_one"
            value={addressDetails.address_one}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            required
            variant="outlined"
            label="Address 2"
            name="address_two"
            value={addressDetails.address_two}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              value={addressDetails.country}
              options={countries}
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              fullWidth
              label="Country"
              name="country"
              onChange={(event, newValue) => {
                setAddressDetails({
                  ...addressDetails,
                  country: newValue,
                  state: "",
                });
                if (newValue) {
                  setStatesList(newValue.states || []);
                } else {
                  setStatesList([]);
                }
              }}
              renderOption={(props, option, index) => (
                <Box
                  key={index}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img loading="lazy" width="20" src={option.flag} alt="" />
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <Input {...params} label="Country" required />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              options={statesList || []}
              fullWidth
              value={addressDetails.state}
              isOptionEqualToValue={(option, value) => option === value}
              label="State"
              name="state"
              onChange={(event, newValue) => {
                setAddressDetails({
                  ...addressDetails,
                  state: newValue,
                });
              }}
              renderOption={(props, option, index) => (
                <Box
                  key={index}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <Input
                  {...params}
                  label="State"
                  required={statesList.length > 0}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            required
            variant="outlined"
            label="City"
            name="city"
            value={addressDetails.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            required
            variant="outlined"
            label="Zip Code"
            name="zip_code"
            value={addressDetails.zip_code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            required
            variant="outlined"
            label="Contact Person"
            name="contact_person"
            value={addressDetails.contact_person}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Input
            required
            variant="outlined"
            label="Contact Phone"
            name="contact_phone"
            value={addressDetails.contact_phone}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Btn type="submit">Save Address</Btn>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Btn
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Btn>
        </Grid>
      </Grid>
    </Form>
  );
}

export default AddressFormComponent;
