import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import ActressImg from "../../images/cart-images/shop_v1_img.webp";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: `${theme.spacing(2)} 0px`,
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  height: "auto",
  padding: `${theme.spacing(2)} 0px`,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const RightContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: "#F6F6F4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  boxSizing: "border-box",
  padding: `${theme.spacing(2)} 0px`,
  [theme.breakpoints.down("md")]: {
    backgroundColor: "#fff",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "65%",
  margin: "0px auto",
  [theme.breakpoints.down("md")]: {
    width: "85%",
  },
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "32px",
  color: "#000",
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "23px",
  color: "#666",
}));

const ShopHeader = () => {
  return (
    <Container>
      <InnerContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <ImageContainer>
              <Image src={ActressImg} alt="actress_img" />
            </ImageContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <RightContainer>
              <TextContainer>
                <Text>Level Up</Text>
                <SubText>
                  For life at the gym and beyond. Discover the spring activewear
                  collection.
                </SubText>
              </TextContainer>
            </RightContainer>
          </Grid>
        </Grid>
      </InnerContainer>
    </Container>
  );
};

export default ShopHeader;
