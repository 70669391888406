import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import theme from "../utils/themes";
import sub_img_one from "../images/products-listing-image/sub-actress-img-1.webp";
import sub_img_two from "../images/products-listing-image/sub-actress-img-2.webp";
import sub_img_three from "../images/products-listing-image/sub-actress-img-3.webp";
import sub_img_four from "../images/products-listing-image/sub-actress-img-4.webp";
import ProductsCard from "./card_widgets/ProductsCard";
import SizeGuideModel from "./card_widgets/SizeGuideModel";
import FindMySizeModel from "./card_widgets/FindMySizeModel";
import ProductImgCarousel from "./card_widgets/ProductImgCarousel";
import ReviewsComponent from "../components/productdetails_page_components/ReviewsComponent";
import ProductsCarousel from "../components/productdetails_page_components/ProductsCarousel";
import { useSelector } from "react-redux";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: `${theme.spacing(4)} 0px`,
  minHeight: "calc(100vh - 55px)",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "0px",
  },
}));

const BreadcrumbsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    margin: "auto",
  },
}));

const LinkComponent = styled(Link)(({ theme }) => ({
  width: "100%",
  color: theme.palette.primary.light,
  textDecoration: "none",
  fontSize: "14px",
  "&:hover": {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  padding: `${theme.spacing(2)} 0px`,
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Images = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const TextContainer = styled(Typography)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0px`,
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: theme.palette.primary.main,
}));

const DescText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  color: theme.palette.primary.main,
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 400,
  lineHeight: "36px",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    lineHeight: "24px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
}));

const RightContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "100%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    margin: `${theme.spacing(5)} 0px`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    margin: "20px auto",
  },
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1),
}));

const NewArraivelText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
}));

const ProductTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "30px",
}));

const DisplayTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "23px",
}));

const ReviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "26px",
  gap: theme.spacing(1),
}));

const ReviewsText = styled(Link)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: "20px",
  fontSize: "14px",
  color: theme.palette.primary.light,
  textDecoration: "none",
  "&: hover": {
    color: theme.palette.primary.dark,
  },
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "23px",
}));

const SelectedProductContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const ColorSelectionContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(3),
}));

const ColorText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  color: theme.palette.primary.main,
}));

const SelectSizeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const LinkElement = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  textDecoration: "underline",
}));

const SelectSizeBtn = styled(Button)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  padding: "8px 10px",
}));

const AddToCardBtn = styled(Button)(({ theme }) => ({
  borderRadius: "0px",
  width: "100%",
  height: "100%",
}));

const OffersContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
  textAlign: "center",
  margin: `${theme.spacing(1)} 0px`,
}));

const DisplayProductsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const MenuItemComponent = styled(MenuItem)(({ theme }) => ({
  justifyContent: "center",
}));

const FormControlLabelComponent = styled(FormControlLabel)(
  ({ theme, color }) => ({
    padding: "8px",
    "& ::after": {
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "1rem",
      height: "1rem",
      marginTop: "-0.5rem",
      marginLeft: "-0.5rem",
      borderRadius: "100%",
      backgroundColor: color,
      color: "inherit",
      content: `''`,
    },
  })
);

const SelectComponent = styled(Select)(({ theme }) => ({
  borderRadius: "0px",
  textAlign: "center",
  backgroundColor: "#fff",
  border: "1px solid #000",
  "&:hover": {
    backgroundColor: "#fff",
    borderBottom: "0px",
  },
  "&::before": {
    border: "0px",
  },
  "&::after": {
    border: "0px",
  },
  ".MuiSelect-root": {
    backgroundColor: "blue",
    "&:hover": {
      backgroundColor: "#fff",
      borderBottom: "0px",
    },
    "& ::before": {
      backgroundColor: "orange",
      border: "0px",
    },
  },
  ".MuiSelect-filled": {
    backgroundColor: "#fff",
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
}));

const DescContainer = styled(Typography)(({ theme }) => ({
  width: "100%",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const DescTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "20px",
  color: "#000",
  marginBottom: theme.spacing(6),
}));

const AdditionalInfoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const sizes = ["xs", "s", "m", "l", "xl"];

const data = [
  {
    img: sub_img_one,
    title: "Denim Mini Skirt",
    sale_price: "69.00",
    old_price: null,
    off_percent: null,
  },
  {
    img: sub_img_two,
    title: "Monogram Logo Crewneck T-Shirt",
    sale_price: "23.40",
    old_price: "39.00",
    off_percent: "40",
  },
  {
    img: sub_img_three,
    title: "Ribbed Wool Cardigan",
    sale_price: "99.96",
    old_price: "249.90",
    off_percent: "60",
  },
  {
    img: sub_img_four,
    title: "Women's Jayden Boot",
    sale_price: "75.60 ",
    old_price: "189.00",
    off_percent: "60",
  },
];

const colors = ["red", "green", "blue", "yellow"];

const quantity = [1, 2, 3, 4, 5, 6];

function ProductsDetailsPage() {
  const { slug } = useParams();
  const { products } = useSelector((state) => state.products);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [openSizeChart, setOpenSizechart] = useState(false);
  const [openFindSizeModel, setFindSizeModel] = useState(false);
  const [productDetails, setProductDetails] = useState({
    color: "",
    size: "",
    qty: 1,
  });

  const selectedData = products?.find((data) => data.slug === slug);

  return (
    <>
      <Container>
        <InnerContainer>
          <BreadcrumbsContainer>
            <Breadcrumbs aria-label="breadcrumb">
              <LinkComponent underline="hover" color="inherit" href="/">
                Women Outerwear
              </LinkComponent>
              <LinkComponent
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                Apparel
              </LinkComponent>
              <LinkComponent
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                Outerwear
              </LinkComponent>
            </Breadcrumbs>
          </BreadcrumbsContainer>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <Grid container spacing={!matches && 2}>
                {matches ? (
                  <ProductImgCarousel images={selectedData.galleries} />
                ) : (
                  <>
                    {selectedData.galleries.map((img, index) => (
                      <Grid item md={6} key={index}>
                        <Images src={img.image} alt={img.alt} />
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <RightContainer>
                <InfoContainer>
                  <NewArraivelText>New Arrival</NewArraivelText>
                  <ProductTitle>{selectedData.name}</ProductTitle>
                  <ReviewContainer>
                    <Rating
                      name="read-only"
                      value={3}
                      readOnly
                      size="small"
                      sx={{
                        color: "#000",
                      }}
                    />
                    <ReviewsText>
                      <LinkElement>
                        {" "}
                        <span> (2) </span> Questions & Answers
                      </LinkElement>
                    </ReviewsText>
                  </ReviewContainer>
                  <PriceText>$99.00</PriceText>
                </InfoContainer>
                <SelectedProductContainer>
                  <ColorSelectionContainer>
                    <ColorText>
                      <span
                        style={{
                          color: theme.palette.primary.light,
                          fontWeight: 400,
                        }}
                      >
                        Color{" "}
                      </span>
                      Classic White
                    </ColorText>
                    <FormControl>
                      <RadioGroup
                        row
                        name="radio-buttons-group"
                        onChange={(event) => {
                          if (event.target.checked) {
                            setProductDetails({
                              ...productDetails,
                              color: event.target.value,
                            });
                          }
                        }}
                      >
                        {colors.map((color) => (
                          <FormControlLabelComponent
                            value={productDetails.color}
                            control={
                              <Radio
                                value={color}
                                checked={productDetails.color === color}
                                sx={{
                                  "& .Mui-checked": {
                                    padding: "10px",
                                    border: "1px solid red",
                                    background: "red",
                                    "& :target": {
                                      border: "1px solid #000",
                                    },
                                  },
                                }}
                              />
                            }
                            color={color}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </ColorSelectionContainer>
                  <SelectSizeContainer>
                    <TextContainer
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "0px",
                      }}
                    >
                      <Text>Select Size</Text>
                      <Text>
                        <LinkElement
                          onClick={() => {
                            setFindSizeModel(true);
                          }}
                        >
                          Find My Size
                        </LinkElement>
                      </Text>
                    </TextContainer>
                    <Grid container spacing={1}>
                      {sizes.map((size, index) => (
                        <Grid item xs={3} sm={2} md={3} lg={2} key={index}>
                          <SelectSizeBtn
                            variant={
                              productDetails.size === size
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setProductDetails({
                                ...productDetails,
                                size: size,
                              });
                            }}
                          >
                            {size}
                          </SelectSizeBtn>
                        </Grid>
                      ))}
                    </Grid>
                    <TextContainer sx={{ margin: "0px", rowGap: "8px" }}>
                      <Text>
                        <LinkElement
                          onClick={() => {
                            setOpenSizechart(true);
                          }}
                        >
                          Size Guide
                        </LinkElement>
                      </Text>
                      <Text>Model is 5'9" wearing a size S</Text>
                      <Text>In Stock</Text>
                    </TextContainer>
                    <Grid container spacing={1}>
                      <Grid item xs={3} sm={3} md={3}>
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Qty
                          </InputLabel>
                          <SelectComponent
                            value={productDetails.qty}
                            label="Qty"
                            onChange={(event) => {
                              setProductDetails({
                                ...productDetails,
                                qty: event.target.value,
                              });
                            }}
                          >
                            {quantity.map((qty) => (
                              <MenuItemComponent value={qty}>
                                {qty}
                              </MenuItemComponent>
                            ))}
                          </SelectComponent>
                        </FormControl>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        <AddToCardBtn variant="contained">
                          Add to cart
                        </AddToCardBtn>
                      </Grid>
                    </Grid>
                    <OffersContainer>
                      <Text>Free Standard Shipping on $75+</Text>
                      <Text>
                        4 interest-free payments of $19.80 with Klarna. Learn
                        More
                      </Text>
                      <Text>or 4 interest-free payments of $24.75 with </Text>
                    </OffersContainer>
                  </SelectSizeContainer>
                </SelectedProductContainer>
                <DisplayProductsContainer>
                  <DisplayTitle sx={{ marginBottom: theme.spacing(2) }}>
                    Complete the Look
                  </DisplayTitle>
                  <Grid container spacing={2}>
                    {data.map((item, index) => (
                      <Grid item key={index} xs={6} sm={6} md={6}>
                        <ProductsCard data={item} />
                      </Grid>
                    ))}
                  </Grid>
                </DisplayProductsContainer>
              </RightContainer>
            </Grid>
          </Grid>
          <TextContainer
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "90%",
                margin: "10px auto",
              },
            }}
          >
            <Text>About the Classic Denim Trucker Jacket</Text>
            <Description>
              An essential denim jacket crafted from cotton stretch denim for an
              effortless, comfortable fit. Made with long sleeves, a point
              collar, and button closures along the front. Features dual chest
              pockets and finished with topstitching details for structured
              shape.
            </Description>
          </TextContainer>
          <DescContainer>
            <DescTitle>Description</DescTitle>
            <TextContainer sx={{ width: "90%", margin: "auto" }}>
              <DescText>Sed do eiusmod tempor incididunt ut labore</DescText>
              <List sx={{ listStyleType: "disc" }}>
                <ListItem
                  sx={{
                    display: "list-item",
                    marginLeft: "24px",
                    padding: "6px 0px",
                    width: "fit-content",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "24px",
                    },
                  }}
                >
                  Top flap design
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                    marginLeft: "24px",
                    padding: "6px 0px",
                    width: "fit-content",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "24px",
                    },
                  }}
                >
                  Button closure
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                    marginLeft: "24px",
                    padding: "6px 0px",
                    width: "fit-content",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "24px",
                    },
                  }}
                >
                  Adjustable logo shoulder strap
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                    marginLeft: "24px",
                    padding: "6px 0px",
                    width: "fit-content",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "24px",
                    },
                  }}
                >
                  Embossed CK monogram logo at the front
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                    marginLeft: "24px",
                    padding: "6px 0px",
                    width: "fit-content",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "24px",
                    },
                  }}
                >
                  Imported
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                    marginLeft: "24px",
                    padding: "6px 0px",
                    width: "fit-content",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "24px",
                    },
                  }}
                >
                  100% polyurethane
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                    marginLeft: "24px",
                    padding: "6px 0px",
                    width: "fit-content",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "24px",
                    },
                  }}
                >
                  11" wide x 5.5" tall x 3.1" deep
                </ListItem>
              </List>
              <DescText>Style #: 36200934-020</DescText>
              <DescText>Universal Style #: 40W0934</DescText>
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <TextContainer>
                    <DescText>Why choose product?</DescText>
                    <List sx={{ listStyleType: "disc" }}>
                      <ListItem
                        sx={{
                          display: "list-item",
                          marginLeft: "24px",
                          padding: "4px 0px",
                          width: "fit-content",
                          [theme.breakpoints.down("sm")]: {
                            marginLeft: "24px",
                          },
                        }}
                      >
                        Creat by cotton fibric with soft and smooth
                      </ListItem>
                      <ListItem
                        sx={{
                          display: "list-item",
                          marginLeft: "24px",
                          padding: "4px 0px",
                          width: "fit-content",
                          [theme.breakpoints.down("sm")]: {
                            marginLeft: "24px",
                          },
                        }}
                      >
                        Simple, Configurable (e.g. size, color, etc.), bundled
                      </ListItem>
                      <ListItem
                        sx={{
                          display: "list-item",
                          marginLeft: "24px",
                          padding: "4px 0px",
                          width: "fit-content",
                          [theme.breakpoints.down("sm")]: {
                            marginLeft: "24px",
                          },
                        }}
                      >
                        Downloadable/Digital Products, Virtual Products
                      </ListItem>
                    </List>
                  </TextContainer>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextContainer>
                    <DescText>Sample Number List</DescText>
                    <List sx={{ listStyleType: "desc" }}>
                      <ListItem
                        sx={{
                          display: "list-item",
                          marginLeft: "24px",
                          padding: "4px 0px",
                          width: "fit-content",
                          [theme.breakpoints.down("sm")]: {
                            marginLeft: "24px",
                          },
                        }}
                      >
                        Create Store-specific attrittbutes on the fly
                      </ListItem>
                      <ListItem
                        sx={{
                          display: "list-item",
                          marginLeft: "24px",
                          padding: "4px 0px",
                          width: "fit-content",
                          [theme.breakpoints.down("sm")]: {
                            marginLeft: "24px",
                          },
                        }}
                      >
                        Simple, Configurable (e.g. size, color, etc.), bundled
                      </ListItem>
                      <ListItem
                        sx={{
                          display: "list-item",
                          marginLeft: "24px",
                          padding: "4px 0px",
                          width: "fit-content",
                          [theme.breakpoints.down("sm")]: {
                            marginLeft: "24px",
                          },
                        }}
                      >
                        Downloadable/Digital Products, Virtual Products
                      </ListItem>
                    </List>
                  </TextContainer>
                </Grid>
              </Grid>
              <Text>Lining</Text>
              <Text>100% Polyester, Main: 100% Polyester.</Text>
            </TextContainer>
          </DescContainer>
          <AdditionalInfoContainer>
            <TextContainer>
              <DescText>ADDITIONAL INFORMATION</DescText>
              <TextContainer sx={{ width: "90%", margin: "auto" }}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignContent="flex-start"
                >
                  <Text sx={{ width: "100px" }}>Size</Text>
                  <Text>M</Text>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignContent="flex-start"
                >
                  <Text sx={{ width: "100px" }}>color</Text>
                  <Text>#696969</Text>
                </Stack>
              </TextContainer>
            </TextContainer>
          </AdditionalInfoContainer>
          {/* Related Products  */}
          <ProductsCarousel />
          {/*  Reveiw component */}
          <ReviewsComponent />
        </InnerContainer>
      </Container>
      <SizeGuideModel open={openSizeChart} setOpen={setOpenSizechart} />
      <FindMySizeModel open={openFindSizeModel} setOpen={setFindSizeModel} />
    </>
  );
}

export default ProductsDetailsPage;
