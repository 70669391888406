import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: "#000",
  fontSize: "26px",
  lineHeight: "32px",
  marginBottom: theme.spacing(2),
}));

const AccordionSummaryContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const AccordionDetailsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: `${theme.spacing(3)} 0px`,
}));

const AccordionSummaryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  color: theme.palette.primary.main,
}));

const AccordionSummaryDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "30px",
  color: theme.palette.primary.main,
}));

const AccordionComponent = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none",
    "&::before": {
      content: "none",
    },
  },
}));

const AccordionSummaryComponent = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: "1px solid #e4e4e4",
  "&.MuiAccordionSummary-root": {
    padding: "0px",
  },
}));

const AccordionDetailsComponent = styled(AccordionDetails)(({ theme }) => ({
  padding: "0px",
}));

function FaqCard({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Title>{data.title}</Title>

      {data.faqs.map((faq_data, index) => {
        return (
          <AccordionComponent
            key={index}
            expanded={expanded === faq_data.id}
            onChange={handleChange(faq_data.id)}
          >
            <AccordionSummaryComponent expandIcon={<ExpandMoreIcon />}>
              <AccordionSummaryContainer>
                <AccordionSummaryTitle>{faq_data.ques}</AccordionSummaryTitle>
              </AccordionSummaryContainer>
            </AccordionSummaryComponent>
            <AccordionDetailsComponent>
              <AccordionDetailsContainer>
                <AccordionSummaryDesc>{faq_data.answ}</AccordionSummaryDesc>
              </AccordionDetailsContainer>
            </AccordionDetailsComponent>
          </AccordionComponent>
        );
      })}
    </Container>
  );
}

export default FaqCard;
