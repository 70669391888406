import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { sidebarNavlinks } from "../../utils/textUtils";
import Cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDetails,
  resetUserState,
} from "../../features/authentication/authSlice";
import Cookies from "js-cookie";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    rowGap: "0px",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: "#E4E4E4",
  padding: `${theme.spacing(1)} 20px`,
  height: "55px",
  display: "flex",
  justifyContent: "flex-between",
  alignItems: "center",
}));

const Body = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(3),
  padding: "10px 20px",
  [theme.breakpoints.down("sm")]: {
    padding: "40px 20px",
  },
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  position: "relative",
  textTransform: "uppercase",
}));

const CloseBtnContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "-4",
  right: "10px",
  [theme.breakpoints.down("sm")]: {
    right: "10px",
  },
}));

const NavlinkComponent = styled(NavLink)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "24px",
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

function UserNavlinksSidebar({ handleClose, setLoginModel }) {
  const token = Cookies.get("access");
  const userDetailsFetched = useRef(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    Cookie.remove("access");
    Cookie.remove("refresh");
    setLoginModel(false);
    handleClose();
    dispatch(resetUserState());
  };

  useEffect(() => {
    if (!userDetailsFetched.current) {
      if (token) {
        dispatch(fetchUserDetails());
        userDetailsFetched.current = true;
      }
    }
  }, [dispatch, token]);

  return (
    <Container>
      <Header>
        <HeaderTitle>Hi, {user?.first_name}</HeaderTitle>
        <CloseBtnContainer>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CloseBtnContainer>
      </Header>
      <Body>
        {sidebarNavlinks.map((menu, index) => {
          return (
            <NavlinkComponent onClick={handleClose} key={index} to={menu.path}>
              {menu.menu}
            </NavlinkComponent>
          );
        })}
        <NavlinkComponent onClick={handleLogout}>Logout</NavlinkComponent>
      </Body>
    </Container>
  );
}

export default UserNavlinksSidebar;
