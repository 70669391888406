import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { trackorder_context } from "../utils/textUtils";
import { Link } from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "20px 4.6875rem",
  margin: "auto",
  minHeight: "calc(100vh - 55px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "40.625rem",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(6),
  padding: `${theme.spacing(8)} 0px`,
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const LoginBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  fontWeight: 400,
  lineHeight: "28px",
  color: theme.palette.primary.main,
  textAlign: "center",
}));

const LinkEle = styled(Link)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  margin: `${theme.spacing(1)} 0px`,
  width: "fit-content",
  color: "#767676",
}));

function TrackOrderPage() {
  const [orderDetails, setOrderDetails] = useState({
    order_number: "",
    email: "",
    zip_code: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrderDetails({
      ...orderDetails,
      [name]: value,
    });
  };

  const submitBtnHandler = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <InnerContainer>
        <Title>{trackorder_context.title}</Title>
        <Form component="form" onSubmit={submitBtnHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={12} md={12}>
              <Stack spacing={2}>
                <Input
                  name="order_number"
                  value={orderDetails.order_number}
                  onChange={handleChange}
                  label={trackorder_context.input_text_one}
                  type="text"
                  required
                />{" "}
                <LinkEle to="/#">{trackorder_context.link_one}</LinkEle>
              </Stack>
            </Grid>
            <Grid item xs={12} sx={12} md={12}>
              <Input
                name="email"
                value={orderDetails.email}
                onChange={handleChange}
                label={trackorder_context.input_text_two}
                type="email"
                required
              />
            </Grid>
            <Grid item xs={12} sx={12} md={12}>
              <Input
                name="zip_code"
                value={orderDetails.zip_code}
                onChange={handleChange}
                label={trackorder_context.input_text_three}
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sx={12} md={12}>
              <Stack spacing={1}>
                <LoginBtn type="submit">{trackorder_context.btn_text}</LoginBtn>
                <LinkEle to="/#">{trackorder_context.link_two}</LinkEle>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </InnerContainer>
    </Container>
  );
}

export default TrackOrderPage;
