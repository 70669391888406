import {
  Box,
  Card,
  IconButton,
  Rating,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Carousel from "react-multi-carousel";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import { cartbar_context } from "../../utils/textUtils";
import "react-multi-carousel/lib/styles.css";
import theme from "../../utils/themes";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  marginBottom: `${theme.spacing(5)}`,
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  margin: "auto",
  marginBottom: "10px",
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  margin: "auto",
  marginBottom: theme.spacing(2),
}));

const ImgEle = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  objectFit: "cover",
}));

const CardDiv = styled(Card)(({ theme }) => ({
  margin: "0px 10px",
  boxShadow: "none",
  borderRadius: "0px",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "26px",
  lineHeight: "30px",
  marginBottom: theme.spacing(3),
}));

const ProductTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "24px",
  marginBottom: theme.spacing(1),
}));

const ProductPrice = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#767676",
}));

const CustomLeftArrow = ({ onClick }) => {
  return (
    <IconButton
      sx={{
        position: "absolute",
        left: "calc(1% + 1px)",
        outline: 0,
        transition: "all 0.5s",
        borderRadius: "35px",
        zIndex: 1,
        border: 0,
        background: "rgba(0, 0, 0, 0.5)",
        minWidth: "43px",
        minHeight: "43px",
        opacity: 1,
        cursor: "pointer",
        backgroundColor: "#fff",
        "&: hover": {
          backgroundColor: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      }}
      onClick={onClick}
    >
      <KeyboardArrowLeftSharpIcon />
    </IconButton>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <IconButton
      sx={{
        position: "absolute",
        outline: 0,
        transition: "all 0.5s",
        borderRadius: "35px",
        zIndex: 1,
        border: 0,
        background: "rgba(0, 0, 0, 0.5)",
        minWidth: "43px",
        minHeight: "43px",
        opacity: 1,
        cursor: "pointer",
        right: "calc(1% + 1px)",
        backgroundColor: "#fff",
        "&: hover": {
          backgroundColor: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightSharpIcon />
    </IconButton>
  );
};

function ProductsCarousel() {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      <InnerContainer>
        <Title>RELATED PRODUCTS</Title>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          partialVisbile={matches ? true : false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 4,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 90,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {cartbar_context.cartData.map((data, index) => {
            return (
              <CardDiv key={index}>
                <ImgContainer>
                  <ImgEle src={data.img} alt="product-img" />
                </ImgContainer>
                <Stack spacing={1}>
                  <Box>
                    <ProductTitle>{data.title}</ProductTitle>
                    <ProductPrice>${data.price}</ProductPrice>
                  </Box>
                  <Stack direction="row" spacing={1}>
                    <Rating
                      name="read-only"
                      value={data.qty}
                      readOnly
                      size="small"
                      sx={{
                        color: "#000",
                      }}
                    />
                    <Typography>(6)</Typography>
                  </Stack>
                </Stack>
              </CardDiv>
            );
          })}
        </Carousel>
      </InnerContainer>
    </Container>
  );
}

export default ProductsCarousel;
