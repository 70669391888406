import { Box, Drawer, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import ShopHeader from "../components/shop_page_components/ShopHeader";
import FiltersComponentV1 from "../components/shop_page_components/FiltersComponentV1";
import ProductListingComponentV1 from "../components/shop_page_components/ProductListingComponentV1";
import { fetchProducts } from "../features/products/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import FilterSelection from "../components/shop_page_components/FilterSelection";
import theme from "../utils/themes";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "#fff",
}));

const ShopPageV1 = () => {
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const { products, pages } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    currentPage: 1,
    price: [50, 9000],
    size: "",
    color: [],
    sort: "",
  });

  const handleChange = (event, value) => {
    setFilters({
      ...filters,
      currentPage: value,
    });
  };

  useEffect(() => {
    const { currentPage, price, size, color, sort } = filters;
    dispatch(fetchProducts({ currentPage, price, size, color, sort }));
    window.scrollTo(0, 0);
  }, [dispatch, filters]);

  return (
    <>
      <Container>
        <ShopHeader />
        <FiltersComponentV1
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
        />
        <ProductListingComponentV1
          filters={filters}
          pages={pages}
          handleChange={handleChange}
          data={products}
        />
      </Container>
      <Drawer
        open={open}
        anchor={matches ? "bottom" : "right"}
        onClose={() => {
          setOpen(false);
        }}
      >
        <FilterSelection
          filters={filters}
          setFilters={setFilters}
          setOpen={setOpen}
        />
      </Drawer>
    </>
  );
};

export default ShopPageV1;
