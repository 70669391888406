import {
  Alert,
  Backdrop,
  Box,
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  resetErrorState,
  verifyEmail,
  verifyOtp,
} from "../../features/authentication/authSlice";
import Countdown, { zeroPad } from "react-countdown";
import { red } from "@mui/material/colors";
import loader from "../../images/loader.gif";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  margin: "auto",
  minHeight: "calc(100vh - 55px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  rowGap: "14px",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    padding: "30px",
  },
}));

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
  "& input[type='text']": {
    inputMode: "none", // Disable arrow key behavior
    "-webkit-appearance": "none", // Hide spinners for WebKit browsers
    margin: 0, // Remove any default margin
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

const SubmitBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  marginTop: "8px",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const LinkBtn = styled(Link)(({ theme }) => ({
  fontWeight: "400",
  lineHeight: "24px",
  color: theme.palette.primary.main,
  transition: "0.4s",
  textAlign: "right",
  "&: hover": {
    color: "#c32929",
  },
}));

const ResendBtn = styled(Button)(({ theme }) => ({
  fontWeight: "400",
  lineHeight: "24px",
  color: theme.palette.primary.main,
  transition: "0.4s",
  textAlign: "right",
  textDecoration: "underline",
  padding: "0px",
  "&: hover": {
    backgroundColor: "#fff",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#767676",
  textAlign: "center",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  fontWeight: 400,
  lineHeight: "28px",
  color: theme.palette.primary.main,
}));

const NotValidText = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontWeight: "400",
  color: "#6c757d",
  marginBottom: "4px!important",
}));

const AlertComponent = styled(Alert)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  fontWeight: "500",
  backgroundColor: red[100],
  borderRadius: "0px",
  "& .MuiAlert-icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiAlert-action": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const LoadingLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
}));

function OtpComponent() {
  const { error, userEmailForVerification, status } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [otp, setOtp] = useState("");
  const [allowResend, setAllowResend] = useState(false);
  const [otpTimeLimit, setOtpTimeLimit] = useState(
    localStorage.getItem("otp_time_limit")
  );

  const submitBtnHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(verifyOtp(otp)).unwrap();
      if (response.status === 200) {
        navigate("/add-newpassword");
        setOtp("");
      }
      console.log(response);
    } catch (error) {
      console.log("Error while verifying OTP:", error);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const otpRegex = /^\d{0,4}$/; // Regex to allow only up to 4 digits
    if (otpRegex.test(value)) {
      setOtp(value);
    }
  };

  const rendOtpBtnHandler = async () => {
    try {
      setOpen("");
      setAllowResend(true);
      const response = await dispatch(
        verifyEmail(userEmailForVerification)
      ).unwrap();
      if (response.success) {
        setOtpTimeLimit(response.valid_till);
        setOtp(true);
      }
    } catch (error) {
      console.log("Error while getting new OTP:", error);
    }
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setAllowResend(false);
      return (
        <NotValidText sx={{ color: red[500] }}>
          Your OTP has been expired. Please click on 'Resend OTP' to receive a
          new one.
        </NotValidText>
      );
    } else {
      setAllowResend(true);
      return (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            sx={{
              "&.MuiSnackbar-root": {
                top: "100px",
              },
            }}
            onClose={() => {
              setOpen(false);
            }}
          >
            <Alert
              onClose={() => {
                setOpen(false);
              }}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              OTP sent to your email. Check inbox to proceed
            </Alert>
          </Snackbar>
          <NotValidText>
            Your OTP will be expire in{" "}
            <span style={{ fontWeight: 600, fontSize: "16px" }}>
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
          </NotValidText>
        </>
      );
    }
  };

  return (
    <>
      {status === "loading" && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            "&.MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
          open={true}
        >
          <LoadingLogo src={loader} alt="site-loader" />
        </Backdrop>
      )}
      <Container>
        <Stack alignItems="center" spacing={1}>
          <Title>VERIFY OTP</Title>
          <Countdown
            key={otpTimeLimit}
            date={otpTimeLimit}
            renderer={renderer}
          />
        </Stack>
        <Form component="form" onSubmit={submitBtnHandler}>
          {error && (
            <AlertComponent
              onClose={() => {
                dispatch(resetErrorState());
              }}
              severity="error"
            >
              {error}
            </AlertComponent>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sx={12} md={12}>
              <Input
                name="first_name"
                value={otp}
                onChange={handleChange}
                label="Otp"
                type="number"
                required
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 4,
                }}
              />
            </Grid>
            <Grid item xs={12} sx={12} md={12}>
              <ResendBtn
                disabled={allowResend}
                isDisabled={allowResend}
                disableRipple
                onClick={rendOtpBtnHandler}
              >
                Resend OTP
              </ResendBtn>
              <SubmitBtn type="submit" variant="l">
                Submit
              </SubmitBtn>
            </Grid>
            <Grid item xs={12} sx={12} md={12}>
              <Text>
                Back to <LinkBtn to="/login">Login</LinkBtn>
              </Text>
            </Grid>
          </Grid>
        </Form>
      </Container>
    </>
  );
}

export default OtpComponent;
