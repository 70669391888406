import React from 'react'
import { Navigate } from 'react-router';
import Cookies from "js-cookie";

const RequireAuth = ({ children }) => {
    const token = Cookies.get("access")
    const isLoggedIn = token;
    return isLoggedIn ? (children) : (<Navigate to='/login' />)
}

export default RequireAuth