import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Container = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "100%",
  boxShadow: "none",
  borderRadius: "0px",
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const ImgElement = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

const SmallText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#767676",
  fontSize: "14px",
  lineHeight: "24px",
}));

const ProductTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#000",
  fontSize: "16px",
  width: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#000",
  lineHeight: "28px",
}));

function ProductCard({ data }) {
  return (
    <Container>
      <ImgContainer>
        <ImgElement src={data.img} alt="product-img" />
      </ImgContainer>
      <SmallText>{data.category}</SmallText>
      <ProductTitle>{data.title}</ProductTitle>
      <PriceText>${data.price}</PriceText>
    </Container>
  );
}

export default ProductCard;
