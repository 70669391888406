import {
  Alert,
  Backdrop,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  resetErrorState,
  setUserEmailForVerification,
  verifyEmail,
} from "../../features/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../images/loader.gif";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  margin: "auto",
  minHeight: "calc(100vh - 55px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  rowGap: theme.spacing(4),
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
}));

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(3),
  alignItems: "center",
  width: "30%",
  margin: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  fontWeight: 400,
  lineHeight: "28px",
  color: theme.palette.primary.main,
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const SubmitBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const LinkBtn = styled(Link)(({ theme }) => ({
  fontWeight: "400",
  lineHeight: "24px",
  color: theme.palette.primary.main,
  transition: "0.4s",
  textAlign: "right",
  "&: hover": {
    color: "#c32929",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#767676",
  textAlign: "center",
}));

const ErrorAlert = styled(Alert)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  fontWeight: "500",
  borderRadius: "0px",
  backgroundColor: red[100],
  "& .MuiAlert-icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiAlert-action": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const LoadingLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
}));

function ForgotPasswordComponent() {
  const [email, setEmail] = useState("");
  const { status, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitBtnHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(verifyEmail(email)).unwrap();
      if (response.success) {
        dispatch(setUserEmailForVerification(email));
        navigate("/otp-validate");
      }
    } catch (error) {
      console.log("Error while getting OTP:", error);
    }
  };
  console.log(error);
  return (
    <Container>
      {status === "loading" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <LoadingLogo src={loader} alt="site-loader" />
        </Backdrop>
      )}
      <Form component="form" onSubmit={submitBtnHandler}>
        <Title>Forgot Your Password</Title>
        {error && (
          <ErrorAlert
            onClose={() => {
              dispatch(resetErrorState());
            }}
            severity="error"
          >
            {error}
          </ErrorAlert>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Input
              label="Email Address"
              type="email"
              required
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SubmitBtn type="submit">Submit</SubmitBtn>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Text>
              Back to <LinkBtn to="/login">Login</LinkBtn>
            </Text>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
}

export default ForgotPasswordComponent;
