import {
  Box,
  Typography,
  Input,
  InputAdornment,
  Stack,
  Grid,
  Drawer,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { cartbar_context, searchbar_context } from "../../utils/textUtils";
import ProductCard from "./card_widgets/ProductCard";
import SearchIcon from "../../images/icons/search-icon-black.svg";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: `${theme.spacing(7)} 0px`,
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(3),
}));

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#767676",
}));

const LinkText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#767676",
  transition: "0.3s",
  cursor: "pointer",
  "&: hover": {
    color: "#c32929",
  },
}));

const SearchInput = styled(Input)(({ theme }) => ({
  width: "100%",
  color: "#767676",
  fontWeight: 500,
  fontSize: "14px",
  padding: "5px 0px 10px",
  "& input::placeholder": {
    fontWeight: 600,
    color: "#767676",
  },
}));

const LinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const NoFoundText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 300,
  textAlign: "center",
  height: "150px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Icon = styled("img")(({ theme }) => ({
  width: "20px",
  height: "20px",
  cursor: "pointer",
}));

function SearchTopBar({ open, setOpen, containerRef, input, setInput }) {
  const [filteredData, setFilteredData] = useState([]);
  const data = cartbar_context.cartData;

  const updateSearchData = useCallback(() => {
    setFilteredData(
      data.filter((item) =>
        item.title.toLowerCase().includes(input.toLowerCase())
      )
    );
  }, [input, data]);

  useEffect(() => {
    updateSearchData();
  }, [input, updateSearchData]);

  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={setOpen}
      container={containerRef.current}
      sx={{
        ".MuiDrawer-paper": {
          width: "100%",
          height: "auto",
          top: "70px",
        },
      }}
    >
      <Container>
        <InnerContainer>
          <Text>{searchbar_context.title}</Text>
          <SearchInput
            endAdornment={
              <InputAdornment position="end">
                <Icon src={SearchIcon} alt="search-icon" />
              </InputAdornment>
            }
            placeholder={searchbar_context.search_text}
            variant="standard"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
          {input ? (
            <>
              {filteredData.length > 0 ? (
                <Box>
                  <Grid
                    container
                    spacing={2}
                    columns={{ xs: 4, sm: 8, md: 15 }}
                  >
                    {filteredData?.slice(0, 4).map((data, index) => (
                      <Grid item key={index} xs={1} sm={2} md={3}>
                        <ProductCard data={data} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <NoFoundText>
                  {searchbar_context.noproduct_found_text}
                </NoFoundText>
              )}
            </>
          ) : (
            <LinkContainer>
              <Text>{searchbar_context.links_title}</Text>
              <Stack direction="column" spacing={1}>
                {searchbar_context.links.map((link, index) => (
                  <LinkText key={index} sx={{ fontWeight: 400 }}>
                    {link}
                  </LinkText>
                ))}
              </Stack>
            </LinkContainer>
          )}
        </InnerContainer>
      </Container>
    </Drawer>
  );
}

export default SearchTopBar;
