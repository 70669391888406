import {
  Box,
  Typography,
  List,
  ListItem,
  Drawer,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { navbar_context } from "../../utils/textUtils";
import { useNavigate } from "react-router";
import CloseIcon from "../../images/icons/cross-icon-white.svg";
import Cookies from "js-cookie";
import logo from "../../images/site-logo.svg";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: "#000",
  padding: "30px 0px",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const LinkText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#fff",
  textTransform: "uppercase",
}));

const ListItemComponent = styled(ListItem)(({ theme }) => ({
  display: "list-item",
  paddingTop: "0.625rem",
  paddingBottom: "0.625rem",
  paddingLeft: "0px",
  fontSize: "1rem",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Logo = styled("img")(({ theme }) => ({
  height: "25px",
  width: "100%",
}));

const CrossLogo = styled("img")(({ theme }) => ({
  height: "15px",
  width: "15px",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function MobileNavigationSlider({ open, setOpen }) {
  const token = Cookies.get("access");
  const navigate = useNavigate();

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          width: "80%",
        },
      }}
      anchor="left"
      open={open}
      onClose={setOpen}
    >
      <Container>
        <InnerContainer>
          <Header>
            <Box></Box>
            <LogoContainer>
              <Logo src={logo} alt="site-logo" />
            </LogoContainer>
            <IconButton onClick={setOpen}>
              <CrossLogo src={CloseIcon} alt="close-img" />
            </IconButton>
          </Header>
          <List sx={{ listStyleType: "none" }}>
            {navbar_context.sidebar_menu_links.map((menu, index) => {
              if (menu.menu === "Login" && token) {
                return null;
              } else {
                return (
                  <ListItemComponent
                    onClick={() => {
                      navigate(menu.path);
                      setOpen(false);
                    }}
                    key={index}
                  >
                    <LinkText>{menu.menu}</LinkText>
                  </ListItemComponent>
                );
              }
            })}
          </List>
        </InnerContainer>
      </Container>
    </Drawer>
  );
}

export default MobileNavigationSlider;
