import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const link = process.env.REACT_APP_BACKEND_API;

const refreshToken = async () => {
    try {
        const response = await axios.post(
            `${link}/users/api/token/refresh/`,
            {
                refresh: Cookies.get("refresh"),
            }
        );
        const newAccessToken = response.data.access;
        Cookies.set("access", newAccessToken);
        return newAccessToken;
    } catch (error) {
        throw new Error("Failed to refresh token");
    }
};

export const fetchCountries = createAsyncThunk(
    "address/fetchAddress",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${link}/address/api/get_country/`,
            });
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
);

export const addAddress = createAsyncThunk(
    "address/addAddress",
    async (data, thunkAPI) => {
        let token = Cookies.get("access");
        const makeRequest = async (token) => {
            return axios.post(
                `${link}/address/api/add_address/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
        };
        try {
            let response = await makeRequest(token);
            if (response.status === 201) {
                console.log(response);
                return response.data;
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                try {
                    token = await refreshToken();
                    const response = await makeRequest(token);
                    console.log(response);
                    return response.data;
                } catch (refreshError) {
                    console.log("Error while refreshing Token:", refreshError);
                    return thunkAPI.rejectWithValue(refreshError.response);
                }
            } else {
                console.log(error);
                return thunkAPI.rejectWithValue(error);
            }
        }
    }
);

export const fetchUserAddress = createAsyncThunk("address/fetchUserAddress", async (_, thunkAPI) => {
    let token = Cookies.get("access");
    const makeRequest = async (token) => {
        return await axios.get(`${link}/address/api/list_address/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };
    try {
        const response = await makeRequest();
        if (response.status === 200) {
            return response.data;
        };
    } catch (error) {
        if (error.response && error.response.status === 401) {
            try {
                token = await refreshToken();
                const response = await makeRequest(token);
                if (response.status === 200) {
                    return response.data;
                }
            } catch (refreshError) {
                console.log("Error while refreshing Token", refreshError);
            }
        } else {
            console.log("Error while fetch user's list", error);
            return thunkAPI.rejectWithValue(error);
        };
    };
});

export const deleteAddress = createAsyncThunk(
    "address/deleteAddress",
    async (id, thunkAPI) => {
        const makeRequest = async (token) => {
            return axios.post(`${link}/address/api/del_address/`,
                { address_id: id },
                { headers: { Authorization: `Bearer ${token}` } }
            );
        };

        try {
            const token = Cookies.get("access");
            const response = await makeRequest(token);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                try {
                    const token = await refreshToken();
                    const response = await makeRequest(token);
                    return response.data;
                } catch (refreshError) {
                    console.error("Error while refreshing token:", refreshError);
                    return thunkAPI.rejectWithValue(refreshError);
                }
            } else {
                console.error("Error deleting address:", error);
                return thunkAPI.rejectWithValue(error);
            }
        }
    }
);

const initialState = {
    status: "idle",
    address: [],
    countries: [],
    response_text: "",
    error: null,
};

export const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountries.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.status = "success";
                state.countries = action.payload.country;
                state.error = null;
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.error.message;
            })
            .addCase(addAddress.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addAddress.fulfilled, (state, action) => {
                state.status = "success";
                state.response_text = action.payload.status;
                state.error = null;
            })
            .addCase(addAddress.rejected, (state, action) => {
                state.status = "rejected";
                console.log(action.error);
                state.error = action.error.message;
            })
            .addCase(fetchUserAddress.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUserAddress.fulfilled, (state, action) => {
                state.status = "success";
                state.address = action.payload.addresses;
                state.error = null;
            })
            .addCase(fetchUserAddress.rejected, (state, action) => {
                state.status = "rejected";
                console.log(action.error);
                state.error = action.error.message;
            })
            .addCase(deleteAddress.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteAddress.fulfilled, (state, action) => {
                state.status = "success";
                state.response_text = action.payload.message;
                state.error = null;
            })
            .addCase(deleteAddress.rejected, (state, action) => {
                state.status = "rejected";
                console.log(action.error);
                state.error = action.error.message;
            });
    },
});
