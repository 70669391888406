import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login_context } from "../../utils/textUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  resetErrorState,
} from "../../features/authentication/authSlice";
import Cookie from "js-cookie";
import loader from "../../images/loader.gif";
import { red } from "@mui/material/colors";

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
}));

const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
}));

const LinkBtn = styled(Link)(({ theme }) => ({
  fontWeight: "400",
  lineHeight: "24px",
  color: theme.palette.primary.main,
  transition: "0.4s",
  textAlign: "right",
  "&: hover": {
    color: "#c32929",
  },
}));

const LoginBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: "15px 30px",
  borderRadius: "0px",
  width: "100%",
  "&: hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const LoadingLogo = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
}));

const AlertComponent = styled(Alert)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  fontWeight: "500",
  backgroundColor: red[100],
  borderRadius: "0px",
  "& .MuiAlert-icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiAlert-action": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function LoginComponent() {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    username_email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  const submitBtnHandler = async (event) => {
    event.preventDefault();
    console.log(loginDetails);
    try {
      await dispatch(loginUser(loginDetails)).unwrap();
      const token = Cookie.get("access");
      if (token) {
        navigate("/");
        setLoginDetails({
          username_email: "",
          password: "",
        });
      }
    } catch (err) {
      console.error("Failed to login: ", err);
    }
  };

  return (
    <Form component="form" onSubmit={submitBtnHandler}>
      {status === "loading" && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            "&.MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
          open={true}
        >
          <LoadingLogo src={loader} alt="site-loader" />
        </Backdrop>
      )}
      {error && (
        <AlertComponent
          onClose={() => {
            dispatch(resetErrorState());
          }}
          severity="error"
        >
          {error}
        </AlertComponent>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sx={12} md={12}>
          <Input
            name="username_email"
            value={loginDetails.username_email}
            onChange={handleChange}
            label={login_context.input_text_one}
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <Input
            name="password"
            value={loginDetails.password}
            onChange={handleChange}
            label={login_context.input_text_two}
            type="password"
            required
          />
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <Grid container>
            <Grid item xs={6} sx={6} md={6}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label={login_context.remember_me_text}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} sx={6} md={6}>
              <Stack
                alignItems="flex-end"
                justifyContent="center"
                sx={{ height: "100%" }}
              >
                <LinkBtn to="/forgot-password">Lost password?</LinkBtn>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={12} md={12}>
          <LoginBtn type="submit">LOGIN</LoginBtn>
        </Grid>
      </Grid>
    </Form>
  );
}

export default LoginComponent;
